@import './normalize.scss';
@import './fonts.scss';
@import './variables';
@import './aos/aos.scss';
@import './swiper/swiper-bundle.scss';
@import './custom.scss';

// Blocks
@import './btn.scss';
@import './claim-widget.scss';
@import './claim-btn.scss';
@import './connect-wallets-popup.scss';
@import './connect-wallet-btn.scss';
@import './connect-to-a-wallet-popup.scss';
@import './disclaimer-popup.scss';
@import './header.scss';
@import './hero-section.scss';
@import './main-menu.scss';
@import './primary-title.scss';
@import './find-bridge-form.scss';
@import './rankings.scss';
@import './secondary-title.scss';
@import './secondary-subtitle.scss';
@import './filter-input.scss';
@import './filter-switch.scss';
@import './sort.scss';
@import './form-network-list-popup.scss';
@import './mobile-menu.scss';
@import './tooltip-popup.scss';
@import './page-title.scss';
@import './page-header.scss';
@import './page-tabs.scss';
@import './page-container';
@import './overview.scss';
@import './select.scss';
@import './tooltip-btn.scss';
@import './filter-switch-block.scss';
@import './search-input.scss';
@import './content-tabs.scss';
@import './content-tab.scss';
@import './chart-section.scss';
@import './area-chart-section.scss';
@import './donut-chart-section.scss';
@import './grid-block.scss';
@import './widget.scss';
@import './rankings-tables.scss';
@import './rankings-table.scss';
@import './pagination.scss';
@import './footer.scss';
@import './bottom-widgets.scss';
@import './tooltip.scss';
@import './filter-button.scss';
@import './filters-mobile-panel.scss';
@import './input-label.scss';
@import './back-btn.scss';
@import './popup-bottom.scss';
@import './date-picker-popup.scss';
@import './date-input.scss';
@import './transfers.scss';
@import './reset-button.scss';
@import './replace-button.scss';
@import './transfers-form.scss';
@import './choose-token-button.scss';
@import './max-button.scss';
@import './filter-radio.scss';
@import './info-button.scss';
@import './switch.scss';
@import './popup.scss';
@import './choose-token-popup.scss';
@import './tokens-list-item.scss';
@import './collapse-button.scss';
@import './wallet-info-button.scss';
@import './choose-wallet-popup.scss';
@import './text-link.scss';
@import './copy-button.scss';
@import './wallet-actions-popup.scss';
@import './error-block.scss';
@import './side-popup.scss';
@import './routes-popup.scss';
@import './round-checkbox.scss';
@import './estimated-token.scss';
@import './route-logos.scss';
@import './filter-checkbox.scss';
@import './search-button.scss';
@import './search-popup.scss';
@import './main.scss';
@import './loader.scss';
@import './is-submited-popup.scss';
@import './transaction-path.scss';
@import './page-block.scss';
@import './block-tabs.scss';
@import './transfers-table.scss';
@import './transfer-approve-popup.scss';
@import './allowance-popup.scss';
@import './refused-popup.scss';
@import './rejected-popup.scss';
@import './transfer-submitted-popup.scss';
@import './external-link.scss';
@import './transfer-popup.scss';
@import './refresh-button.scss';
@import './transfers-faq.scss';
@import './block-title.scss';
@import './marked-list.scss';
@import './transfers-container.scss';
@import './transfers-wrapper.scss';
@import './telegram-widget.scss';
@import './swap-details.scss';
@import './alert.scss';
@import './token-cnt.scss';
@import './loader-dark.scss';
@import './loader-blue.scss';
@import './success-message.scss';
@import './link.scss';
@import './faq.scss';
@import './history.scss';
@import './show-all-btn.scss';
@import './copy-btn.scss';
@import './remove-btn.scss';
@import './delete-history-popup.scss';
@import './switch-network-popup.scss';
@import './popup-back-btn.scss';
@import './loader-large.scss';
@import './steps-list.scss';
@import './checkbox.scss';
@import './agreement-popup.scss';
@import './asterizm.scss';
@import './asterizm-wrapper.scss';
@import './cancelled-label.scss';
@import './pending-label.scss';
@import './completed-label.scss';
@import './report-us.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Mulish', 'Roboto', sans-serif;
  color: var(--font-dark);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;

  @media (max-width: 420px) {
    overflow-x: hidden;
  }
}

fieldset {
  border: none;
  padding: 0;
}

.fixed {
  position: fixed;
}

.container {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100vh;
  background: url('../img/page-bg.jpg') no-repeat center top;
  background-size: cover;
  overflow-x: hidden;

  @media (max-width: 768px) {
    min-height: auto;
    background: none;
  }

  &_dark {
    background: #000212 url('../img/cnt-bg-dark.png') no-repeat center top;
    background-size: contain;
  }
}

.wrap {
  width: 100%;
  max-width: 1920px;
  padding: 0 40px;
  margin: 0 auto;

  @media screen and (max-width: 420px) {
    padding: 0 16px;
  }
}

.wrapper {
  width: 100%;
  max-width: 1280px;  
  padding: 0 40px;
  margin: 0 auto;   

  @media (min-width: 1920px) {
    max-width: 1920px;    
  } 

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.swiper-navigation-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 40px;

  & .swiper-button-disabled {
    opacity: 0;
  }
}

.swiper-pagination {
  left: 50%;
  transform: translateX(-50%);
}

.swiper-pagination-bullet:not(:last-of-type) {
  margin-right: 6px;
}

.swiper-button-prev,
.swiper-button-next {
  top: -130px;
  z-index: 9999;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position-y: center;

  &::after {
    display: none;
  }
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.70319 0.288997C7.31051 -0.0993633 6.67736 -0.0958652 6.289 0.296811L0.288997 6.36348C0.102492 6.55205 -0.00145817 6.807 1.52588e-05 7.07222C0.00148916 7.33745 0.108266 7.59122 0.296854 7.77771L6.29685 13.711C6.68955 14.0994 7.32271 14.0958 7.71105 13.7031C8.09938 13.3104 8.09585 12.6773 7.70315 12.289L2.41424 7.05881L7.711 1.70319C8.09936 1.31051 8.09587 0.677358 7.70319 0.288997Z' fill='%230071FF'/%3E%3C/svg%3E");
  background-position-x: 10px;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.296811 0.288997C0.689487 -0.0993633 1.32264 -0.0958652 1.711 0.296811L7.711 6.36348C7.89751 6.55205 8.00146 6.807 7.99998 7.07222C7.99851 7.33745 7.89173 7.59122 7.70315 7.77771L1.70315 13.711C1.31045 14.0994 0.677291 14.0958 0.288954 13.7031C-0.0993824 13.3104 -0.0958453 12.6773 0.296855 12.289L5.58576 7.05881L0.288998 1.70319C-0.0993632 1.31051 -0.0958652 0.677358 0.296811 0.288997Z' fill='%230071FF'/%3E%3C/svg%3E");
  background-position-x: 12px;
}


