.show-all-btn {
	display: flex;
	align-items: center;
	background: none;
	border: none;
	font-weight: 600;
	font-size: 12px;
	line-height: 1;	
	text-decoration: none;
	color: #142438;
	cursor: pointer;
	transition: .1s all;
	padding: 0;

	&::after {
		content: '';
		width: 18px;
		height: 18px;
		background: url('../img/show-all-arrow.svg') no-repeat center;
		background-size: contain;
	}

	&:hover {
		opacity: .7;
	}
}