.page-block {
	position: relative;	
	width: 100%;
	border-radius: 40px;
	background-color: #fff;
	padding-top: 52px;

	@media (max-width: 1280px), (min-width: 1920px) {
		border-radius: 2.08vw;
		padding-top: 2.7vw;
	}

	&__head {
		border-bottom: 1px solid rgba(187, 197, 211, 0.32);
		padding: 0 52px 25px;

		@media (max-width: 1280px), (min-width: 1920px) {
			padding: 0 2.7vw 1.3vw;
		}
	}

	&__content {		
		padding: 40px 52px 60px;

		@media (max-width: 1280px), (min-width: 1920px) {
			padding: 2.08vw 2.7vw 3.125vw;
		}
	}

	&__footer {
		display: flex;
		justify-content: center;
		padding: 0 52px 26px;

		@media (max-width: 1280px), (min-width: 1920px) {
			padding: 0 2.7vw 1.35vw;
		}
	}
}