.choose-token-button {
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;
	width: fit-content;
	min-width: 150px;	
	background: #FFFFFF;
	border: 1px solid #E2E6EE;
	border-radius: 28px;
	text-decoration: none;
	color: var(--font-dark);
	cursor: pointer;
	padding: 12px 40px 12px 12px;

	@media (max-width: 360px) {
		min-width: 120px;	
		padding: 6px 30px 6px 6px;
	}

	&_dark {
		background: none;
		border: none;
		gap: 12px;
		padding: 0;
	}

	&::after {
		content: '';
		display: block;
		width: 9px;
		height: 13px;
		background: url('../img/bottom-up-arrow.svg') no-repeat center;
		background-size: contain;
		position: absolute;
		right: 16px;
		top: 50%;
		transform: translateY(-50%);
	}

	&_dark::after {
		background-image: url('../img/arrow-square-down.svg');
		width: 18px;
		height: 18px;
		right: 0;
	}

	&__icons {
		position: relative;
		width: 28px;
		height: 28px;		
	}

	&__dark &__icons {
		width: 32px;
		height: 32px;		
	}

	&__icon {
		position: absolute;

		&_token {
			top: 50%;
			left: 0;
			width: 28px;
			height: 28px;
			transform: translateY(-50%);
		}

		&_network {
			bottom: -3px;
			right: -.3125vw;
			width: 12px;
			height: 12px;
			border: white 1px solid;
			border-radius: 50%;
		}
	}

	&_dark &__icon_token {
		width: 32px;
		height: 32px;
	}

	&_dark &__icon_network {
		width: 14px;
		height: 14px;
		bottom: -2px;
		right: -6px;
	}

	&__value {
		display: flex;
		flex-direction: column;
		align-items: flex-start;		
	}

	&__token {
		font-size: 14px;
		font-weight: 500;
		margin-bottom: .1vw;
	}

	&_dark &__token {
		font-size: 16px;
		font-weight: 600;
		color: white;		
	}

	&__network {
		font-size: 12px;
		color: #9599AD;
	}

	&_dark &__network {
		font-size: 14px;
		color: #565B78;
	}
}