.filter-switch-block {
	display: flex;
	align-items: center;

	@media (max-width: 768px) {
		width: 100%;
	}

	.filter-switch {
		margin-right: .83vw;

		@media (max-width: 768px) {
			margin-right: 0;
		}
	}

	.tooltip-btn {
		@media (max-width: 768px) {
			display: none;
		}
	}
}