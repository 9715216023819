.wallet-info-button {
	display: flex;
	align-items: center;	
	gap: 4px;	
	background: none;
	border-radius: 12px;
	border: none;
	text-decoration: none;
	color: #fff;
	background: #EBF5FF;
	cursor: pointer;
	padding: 4px;

	@media (max-width: 768px) {
		border-radius: 8px;
		gap: 3px;
		padding: 3px;
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 34px;
		height: 34px;
		border-radius: 8px;
		background-color: #fff;
		position: relative;		

		@media (max-width: 768px) {
			width: 26px;
			height: 26px;
			border-radius: 6px;
		}		
	}

	&:not(.wallet-info-button_multiple) &__logo:hover .tooltip-popup {
		display: none;
	}

	&__main-logo {
		width: 20px;

		@media (max-width: 768px) {
			width: 18px;			
		}
	}
	
	&__network-logo {		
		position: absolute;
		border-radius: 50%;
		border: #fff 1px solid;
		width: 14px;			
		min-width: 14px;
		bottom: -2px;
		right: -3px;

		@media (max-width: 768px) {
			width: 10px;			
			min-width: 10px;
		}
	}

	&__wallet-num {
		display: flex;
		align-items: center;
		gap: 6px;
		background: white;
		height: 34px;		
		border-radius: 8px;
		padding: 0 10px;

		@media (max-width: 768px) {
			height: 26px;
			border-radius: 6px;
		}

		div {
			display: flex;
			flex-direction: column;			
		}

		img {
			width: 24px;	

			@media (max-width: 768px) {
				width: 14px;
			}
		}
	}

	&__num {
		font-size: 16px;
		font-weight: 500;
		color: #003980;	
		
		@media (max-width: 768px) {
			font-size: 12px;
		}
	}

	&__network {
		font-size: 12px;
		color: #99C6FF;
	}

	&__arrow {
		transition: transform .2s;
		margin: 0 8px;
	}

	&.active &__arrow {
		transform: rotate(180deg);
	}
}