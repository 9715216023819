.choose-token-popup {	
	@media (max-width: 768px) {
		background: none;
	}

	.popup__container {
		width: 90vw;
		height: 80vh;
		max-width: 820px;
		padding: 0;

		@media (max-width: 768px) {
			width: 100%;
			height: auto;
			min-height: calc(100vh - 82px);
		}

		&::before {
			@media (max-width: 768px) {
				display: none;
			}
		}
	}

	.popup__close {
		width: 13px;
		height: 13px;
		background: url('../img/close-icon_black.svg') no-repeat center;
		background-size: contain;
		left: auto;
		right: 20px;
		top: 28px;
	}

	&__header {
		border-bottom: 1px solid #EAEEFB;
		padding: 17px 20px 18px;
	}

	&__title {
		font-weight: 800;
		font-size: 24px;
		line-height: 1.25;
		margin: 0 0 18px;
	}

	&__search {
		width: 100%;		

		@media (max-width: 360px) {
			font-size: 12px;		
		}
	}

	&__tokens-cnt {
		display: flex;
		padding: 0 32px;

		@media (max-width: 768px) {
			padding: 0 20px;
		}

		@media (max-width: 368px) {
			padding: 0 16px;
		}
	}

	&__source-tokens {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-auto-rows: 65px;
		gap: 12px 8px ;
		height: calc(80vh - 127px);				
		overflow-y: scroll;
		padding: 24px 16px 0 0;
		margin-right: 20px;

		@media (max-width: 768px) {
			grid-template-columns: 1fr;			
			height: calc(80vh - 30px);			
			padding: 20px 10px 0 0;
			margin-right: 10px;
		}

		&::-webkit-scrollbar {
			width: 4px;					
		}
	
		&::-webkit-scrollbar-track {
			background-color: rgba(0, 113, 255, 0.15);
			width: 4px;
			margin: 24px 0 24px;
		}
	
		&::-webkit-scrollbar-thumb {
			background-color: var(--primary-blue);
			border-radius: 2px;
		}		
	}

	&__source-tokens-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-weight: 600;
		font-size: 15px;
		text-decoration: none;
		height: 65px;
		border: 1px solid #EAEEFB;
		border-radius: 10px;		
		transition: all .1s;
		cursor: pointer;
		padding: 8px 8px 4px;

		@media (max-width: 768px) {
			font-size: 12px;			
		}		

		&.active {
			border-color: var(--primary-blue);
		}

		&:hover {
			color: var(--primary-blue);
			background: #EBF5FF;
			border-color: #EBF5FF;
		}

		&_more {
			justify-content: center;
			color: var(--primary-blue);
		}
	}

	&__tokens-list {		
		flex: auto;
		height: calc(80vh - 127px);				
		overflow-y: scroll;
		border-left: 1px solid #EAEEFB;
		padding: 24px 20px 0;		

		@media (max-width: 768px) {
			height: var(--tokens-list-height);				
			padding: 24px 16px 0;		
		}

		&::-webkit-scrollbar {
			width: 4px;			
		}
	
		&::-webkit-scrollbar-track {
			background-color: rgba(0, 113, 255, 0.15);
			width: 4px;
			margin: 24px 0 24px;
		}
	
		&::-webkit-scrollbar-thumb {
			background-color: var(--primary-blue);
			border-radius: 2px;
		}
	}

	&__tokens-list-empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 140px;

		img {			
			width: 36px;
			margin-bottom: 8px;
		}

		span:first-of-type {
			color: #142438;
			font-size: 16px;
			font-weight: 800;
			margin-bottom: 4px;
		}

		span:last-of-type {
			font-weight: 600;
			font-size: 16px;
			line-height: 1.5;			
			text-align: center;			
			color: #9599AD;

			@media (max-width: 768px) {
				font-size: 12px;
			}
		}
	}

	&__list-title {
		font-size: 14px;
		font-weight: 700;
		color: #4C76AA;	
	}

	&__select {
		margin-bottom: 32px;

		.select__dropdown {
			max-height: 200px;
			overflow-y: scroll;
		
			&::-webkit-scrollbar {
				width: 4px;
			}
		
			&::-webkit-scrollbar-track {
				background-color: rgba(0, 113, 255, 0.15);
				width: 4px;
				border-radius: 2px;
				margin-bottom: .98vw;
			}
		
			&::-webkit-scrollbar-thumb {
				background-color: var(--primary-blue);
				border-radius: 2px;
			}
		}
	}	

	&__owned-tokens {
		margin-bottom: 1.46vw;
	}

	&__all-tokens {
		display: flex;
		flex-direction: column;
		gap: 16px;

		@media (max-width: 360px) {
			gap: 20px;
		}
	}
}