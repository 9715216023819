.connect-to-a-wallet-popup {
	
	.popup__container {
		width: 100%;
		max-width: 500px;
		min-width: auto;
		min-height: auto;
		padding: 24px;
		
		@media (max-width: 768px) {
			padding: 36px 24px 24px;
		}
	}

	.popup__close {
		top: 22px;
		right: 22px;

		@media (max-width: 768px) {
			width: 13px;
			height: 13px;
			position: absolute;
			top: 28px;
			right: 20px;
			left: auto;
		}
	}

	&__title {		
		font-size: 24px;
		font-weight: 800;
		color: var(--font-dark);
		margin: 0 0 10px;

		@media (max-width: 768px) {
			font-size: 20px;
			text-align: center;
		}
	}

	&__description {		
		font-size: 14px;
		line-height: 1.43;	
		color: var(--font-dark);
		margin: 0 0 20px;

		@media (max-width: 768px) {			
			text-align: center;
		}
	}	

	&__wallet-cnt {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
		width: 100%;
		height: 60px;		
		border: 1px solid #E2E7F3;
		border-radius: 18px;
		background: none;
		font-weight: 500;
		font-size: 16px;
		color: white;			
		transition: all .1s;
		padding: 0 16px;

		@media (max-width: 768px) {
			border-radius: 8px;
			font-size: 14px;
			font-weight: 400;
			padding: 0 12px;
		}
	}

	&__wallet-title {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 14px;
		color: #4C76AA;
	}

	&__wallet-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: none;
		width: 36px;
		height: 36px;
		border-radius: 50%;

		@media (max-width: 768px) {
			width: 28px;
			height: 28px;
		}

		&.metamask-wallet {
			background: rgba(244, 138, 41, 0.25);
		}

		&.ever-wallet {
			background: rgba(1, 23, 152, 0.15);
		}
		
		&.tron-wallet {
			background: rgba(183, 62, 49, 0.15);
		}

		&.trust-wallet {
			background: rgba(51, 117, 187, 0.12);
		}

		&.venom-wallet {
			background: rgba(27, 149, 139, 0.14);
		}

		&.wc-wallet {
			background: rgba(59, 153, 252, 0.15);
		}

		img {
			width: 16px;

			@media (max-width: 768px) {
				width: 12px;
			}
		}
	}

	&__waiting {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 14px;	 	
		color: #4C76AA;		

		@media (max-width: 768px) {
			font-size: 12px;
		}
	}

	&__not {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 14px;
		font-weight: 600;	 			
		color: #F29305;

		@media (max-width: 768px) {
			font-size: 12px;
		}
	}

	&__initializing {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 14px;	 	
		color: white;
	}

	&__cta {		
		width: 100%;
		height: 60px;
		margin-top: 15px;		
	}
}