.mobile-menu {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: white;
	transform: scale(.9);
	opacity: 0;	
	transition: all .3s;
	z-index: -1;
	padding: 0 20px 20px;

	&.active {
		transform: scale(1);
		opacity: 1;
		z-index: 999999;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 82px;
		width: 100%;
		margin-bottom: 36px;
	}

	&__logo {				

		img {
			width: 123px;
		}
	}

	&__menu {
		margin-bottom: 42px;
		
		& > ul {
			display: flex;
			flex-direction: column;
			align-items: center;

			li {
				font-size: 20px;
				margin-right: 0;
			}

			li:not(:last-of-type) {
				margin-bottom: 28px;
			}
		}
	}

	&__search {
		border-radius: 8px;
		background: #2760A7 url('../img/search-icon_dark.svg') no-repeat 17px center;    
		height: 52px;    
		width: 100%;
		max-width: 420px;
		border: none;
		outline: none;
		position: relative;
		font-size: 20px;
		font-weight: 600;
		color: white;
		transition: all .2s;
		padding: 0 14px 0 44px; 
		margin-bottom: 16px;		

		&::placeholder {
			color: white;
			opacity: .5;
		}
	}

	&__cta {		  
		width: 100%;		
		max-width: 420px;		
	}

	&__close-btn {
		width: 32px;
		height: 32px;
		background: transparent url('../img/menu-close-button.svg') no-repeat center;
		background-size: contain;
		border: none;				
		cursor: pointer;
	}
}