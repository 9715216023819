.alert {
	gap: 17px;
	width: 300px;
	position: fixed;
	right: 20px;
	top: 100px;
	background: #FF9C19 url('../img/alert-icon.svg') no-repeat 13px 15px;
	border-radius: 12px;	
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	transition: .3s all;
	padding: 12px 36px 12px 52px;

	@media (max-width: 768px) {
		width: calc(100vw - 40px);
		top: auto;
		bottom: 82px;
	}

	&_success {
		background: #24C38E url('../img/success-icon_white.svg') no-repeat 13px 15px;
	}

	&.active {
		z-index: 9999999;
		opacity: 1;
		visibility: visible;
	}

	&__title {
		font-weight: 700;
		font-size: 16px;
		line-height: 1.2;
		color: #FAFAFA;
		margin: 0 0 4px;
	}

	&__caption {
		font-weight: 400;
		font-size: 16px;
		line-height: 1.2;
		color: #FAFAFA;
		word-wrap: break-word;
		margin: 0;	

		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}

	&__close {
		border: none;
		width: 16px;
		height: 16px;
		background: url('../img/alert-close-icon.svg') no-repeat center;
		background-size: contain;
		position: absolute;
		top: 12px;
		right: 12px;
		cursor: pointer;
	}
}