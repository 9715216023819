.external-link {
	display: flex;
	align-items: center;
	gap: 6px;
	font-size: 14px;
	font-weight: 600;
	text-decoration: none;
	white-space: nowrap;
	color: var(--primary-blue);	

	@media (max-width: 1280px), (min-width: 1920px) {
		gap: .3125vw;
		font-size: .73vw;	
	}

	&:after {
		content: '';
		width: 14px;
		height: 14px;
		background: url('../img/export-icon.svg') no-repeat center;
		background-size: contain;
	}
}