.tooltip-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(18, 51, 91, 0.25);
	color: white;
	border-radius: 50%;
	border: none;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s;

	&:hover {
		background-color: var(--primary-blue);
	}

	&_l {
		width: 1.25vw;
		height: 1.25vw;
		font-size: .73vw;
	}

	&_m {
		width: 1.04vw;
		height: 1.04vw;
		font-size: .625vw;
	}

	&_l,
	&_m {
		@media (max-width: 768px) {
			width: 14px;
			height: 14px;
			font-size: 10px;
		}
	}
}