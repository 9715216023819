.search-input {
	background-image: url('../img/search-icon_black.svg');
	background-repeat: no-repeat;
	background-position: 16px center;
	background-size: 19px;
	color: var(--font-dark);
	border-radius: 12px;
	border: none;
	height: 40px;	
	font-size: 16px;
	outline: none;
	box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.02);
	transition: all .1s;	
	padding: 0 24px 0 47px;		

	@media (max-width: 768px) {
		font-size: 14px;
	}

	&::placeholder {
		color: #C3CCD6;
	}

	&_outlined {		
		border: #E2E7F3 1px solid;				
		box-shadow: none;

		&:focus {
			border-color: var(--primary-blue);
		}
	}		
}