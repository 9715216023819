.block-tabs {
	display: flex;
	align-items: flex-end;
	gap: 30px;

	@media (max-width: 1280px), (min-width: 1920px) {
		gap: 1.5625vw;
	}

	&__item {
		display: flex;
		align-items: center;
		gap: 5px;
		border: none;
		background: none;
		font-size: 18px;
		font-weight: 700;
		text-decoration: none;
		color: #959CA5;
		cursor: pointer;
		transition: all .2s;
		padding: 0;

		@media (max-width: 1280px), (min-width: 1920px) {
			gap: .26vw;			
			font-size: .9375vw;
		}

		&:hover {
			color: var(--primary-blue);
		}

		&.active {
			color: var(--primary-blue);
			cursor: auto;
		}
	}

	&__item-counter {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: white;	
		width: 22px;
		height: 22px;
		background-color: #959CA5;
		border-radius: 50%;	
		transition: all .2s;

		@media (max-width: 1280px), (min-width: 1920px) {
			font-size: .73vw;			
			width: 1.15vw;
			height: 1.15vw;
		}
	}	

	&__item.active &__item-counter,
	&__item:hover &__item-counter {
		background-color: var(--primary-blue);
	}
}