.page-title {
	font-family: 'Poppins', sans-serif;
	font-size: 32px;
	font-weight: 800;
	color: var(--font-dark);
	margin: 0 0 1.56vw 0;

	@media (min-width: 1920px) {
		font-size: 1.67vw;		
	}
	
	@media (max-width: 768px) {
		font-size: 24px;
		margin: 0 0 25px 0;
	}
}