.filter-radio {

	&__input {
		visibility: hidden;
		z-index: -1;
		width: 1px;
		height: 1px;
		position: absolute;
	}

	&__text-input {
		height: 40px;
		width: 78px;
		border-radius: 10px;
		border: #F4F6FB 1px solid;
		font-size: 16px;
		font-weight: 600;
		background-color: #F4F6FB;
		color: var(--font-dark);	
		outline: none;	
		padding: 12px;

		@media (max-width: 1280px), (min-width: 1920px) {
			height: 2.08vw;
			width: 4.06vw;
			border-radius: .52vw;			
			font-size: .83vw;			
			padding: .625vw;
		}

		&::placeholder {
			color: #989EB1;
			opacity: 1;
		}

		&:focus {
			border: var(--primary-blue) 1px solid;
		}
	}

	&__button {		
		color: #969EB0;
		font-size: 16px;
		font-weight: 600;
		transition: all .2s;
		cursor: pointer;	
		
		@media (max-width: 1280px), (min-width: 1920px) {
			font-size: .83vw;
		}
	}

	&__input:checked + span {		
		color: var(--primary-blue);
	}

	&__input:checked + input {
		border: var(--primary-blue) 1px solid;
	}
}