.search-button {
	width: 24px;
	height: 24px;
	background: url('../img/search-icon_white.svg') no-repeat center;
	background-size: contain;
	border: none;
	cursor: pointer;
	opacity: .6;
	transition: all .2s;

	@media (min-width: 1920px) {
		width: 1.25vw;
		height: 1.25vw;
	}

	@media (max-width: 1280px) {
		width: 20px;
		height: 20px;	
	}

	&:hover {
		opacity: 1;
	}
}