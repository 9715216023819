.route-logos {
	display: flex;
	position: relative;
	height: 26px;		

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 26px;
		height: 26px;
		object-fit: contain;
	}
}