.wallet-actions-popup {
	
	.popup__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		// min-width: 23.3vw;
		padding-top: 3.85vw;
		padding-bottom: 2.4vw;

		@media (max-width: 768px) {
			padding-top: 30px;
			padding-bottom: 20px;
		}
	}	

	&__logo {				
		width: fit-content;
		position: relative;
		margin-bottom: 8px;

		&-wallet {
			width: 76px;		
			
			@media (max-width: 768px) {
				width: 36px;		
			}
		}

		&-network {
			width: 14px;
			height: 14px;
			border-radius: 50%;
			border: 1px white solid;
			position: absolute;
			bottom: -3px;
			right: -6px;
		}
	}

	&__title {
		font-size: 32px;
		margin-bottom: 10px;

		@media (max-width: 768px) {
			font-size: 16px;
			font-weight: 600;
		}
	}

	&__copy-field {
		display: flex;
		align-items: center;		
		
		span {
			font-size: 16px;
			font-weight: 500;
			color: var(--primary-blue);
			margin-right: 10px;
		}
	}

	&__wallet-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		margin-bottom: 48px;

		@media (max-width: 768px) {
			flex-direction: row;
			gap: 16px;
			margin-bottom: 16px;
		}
	}

	&__copied {
		display: flex;
		align-items: center;
		font-size: .83vw;
		font-weight: 500;
		color: var(--primary-blue);
		margin-bottom: 1.25vw;

		img {
			width: 1.04vw;
			height: 1.04vw;
			margin-left: .625vw;
		}
	}

	&__actions {
		display: flex;
		align-items: center;
		gap: .83vw;		

		@media (max-width: 768px) {
			flex-direction: column;
			gap: 8px;
			width: 100%;
		}
	}

	&__select {		
		width: 200px;
		height: 52px;

		@media (max-width: 768px) {
			display: none;
		}
		
		.select__dropdown {			
			max-height: 200px;
			overflow-y: scroll;

			&::-webkit-scrollbar {
					width: 4px;
				}
			
			&::-webkit-scrollbar-track {
				background-color: rgba(0, 113, 255, 0.15);
				width: 4px;
				border-radius: 2px;
				margin-bottom: .98vw;				
			}
		
			&::-webkit-scrollbar-thumb {
				background-color: var(--primary-blue);
				border-radius: 2px;
			}
		}
	}

	&__balance {
		display: flex;
		align-items: center;
		gap: 7px;
		border-radius: 12px;
		height: 36px;
		background-color: #0071FF26;
		color: var(--primary-blue);
		font-size: 16px;
		font-weight: 500;
		white-space: nowrap;
		padding: 0 8px;				

		img {
			width: 24px;			
		}
	}

	&__switch-network {
		display: none;
		align-items: center;
		justify-content: center;
		gap: 8px;
		height: 52px;
		width: 100%;
		border: 1px solid #E2E7F3;
		background: none;
		border-radius: 10px;
		cursor: pointer;

		@media (max-width: 768px) {
			display: flex;
		}
	}

	&__disconnect {
		width: 100%;
	}
}