.filter-input {
	flex: auto;
	height: 2.7vw;		
	border: none;
	outline: none;
	background-color: #FFFFFF;
	box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.02);
	border-radius: .94vw;
	font-size: .94vw;

	@media (min-width: 1921px) {
		width: 285px;
	}

	@media (max-width: 1200px) {		
		font-size: 1.5vw;
	}

	@media (max-width: 1024px) {
		background-color: #F8F8F8;
		color: var(--font-dark);
		font-size: 14px;
	}

	&::placeholder {
		opacity: 1;
		color: #A0A0A0;
	}	
}