.select {
	position: relative;	
	border-radius: 18px;

	@media (max-width: 1280px), (min-width: 1920px) {
		border-radius: .94vw;
	}

	&.active {
		box-shadow: 0px 0px 5.98214px rgba(0, 0, 0, 0.06), 0px 11.9643px 23.9286px rgba(0, 0, 0, 0.1);
	}

	&_small {
		border-radius: .78vw;
		min-width: 300px;
	}

	&_smallest {
		border-radius: .42vw;
	}

	&__button {
		display: flex;
		align-items: center;
		position: relative;		
		color: var(--font-dark);
		text-decoration: none;
		background-color: white;
		border-radius: 18px;
		font-size: 18px;
		cursor: pointer;
		height: 52px;
		overflow: hidden;
		box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.02);
		user-select: none;
		padding: 0 60px 0 16px;		
		
		@media (max-width: 1280px), (min-width: 1920px) {
			height: 2.7vw;
			border-radius: .94vw;		
			font-size: .94vw;
			padding: 0 3.125vw 0 .83vw;
		}		

		&::after {
			content: '';
			display: block;
			position: absolute;
			right: 16px;
			top: 24px;
			width: 14px;
			height: 8px;
			background: url('../img/dropdown-arrow_blue.svg') no-repeat center;
			background-size: contain;
			transition: all .2s;			
			z-index: 3;

			@media (max-width: 1280px), (min-width: 1920px) {
				right: .83vw;
				top: 1.25vw;
				width: .73vw;
				height: .42vw;
			}
		}

		&.filled {
			color: var(--font-dark);

			&::before {
				content: '';
				display: block;
				height: 50px;
				width: 70px;
				border-radius: 0 .94vw .94vw 0;
				position: absolute;
				right: 0;
				top: 0;
				background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%, white 100%);
				z-index: 2;
			}

			span {
				display: flex;
				align-items: center;
				justify-content: center;
				flex: none;
				width: 32px;
				height: 32px;
				border-radius: 5px;
				background-color: #F1F1F1;
				font-size: 14px;
				font-weight: 800;
				color: #A0A0A0;
				margin-left: 8px;

				img {
					height: 18px;
				}
			}
		}
	}
	
	&_outlined &__button {
		border: #E2E7F3 1px solid;
		box-shadow: none;
	}

	&_small &__button {
		height: 2.6vw;
		border-radius: .78vw;
		font-size: .78vw;

		&::after {
			top: 1.1vw;
			right: .78vw;
		}
	}

	&_smallest &__button {
		height: 1.67vw;
		border-radius: .42vw;
		font-size: .78vw;
		padding-left: .625vw;

		&::after {
			top: .625vw;
			right: .625vw;
		}
	}

	&.active &__button {		
		border-radius: 18px 18px 0 0;		

		@media (max-width: 1280px), (min-width: 1920px) {
			border-radius: .94vw .94vw 0 0;
		}

		&::after {
			transform: rotate(.5turn);
		}
	}

	&__dropdown {
		display: flex;
		flex-direction: column;
		gap: 10px;
		position: absolute;
		left: 0;
		top: 52px;
		font-size: 18px;
		border-radius: 0 0 18px 18px;
		border-top: 1px solid rgba(187, 197, 211, 0.32);
		background-color: white;
		width: 100%;
		visibility: hidden;
		z-index: -1;
		opacity: 0;
		box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.02);
		padding: 16px;

		@media (max-width: 1280px), (min-width: 1920px) {
			gap: .42vw;			
			top: 2.7vw;
			font-size: .94vw;
			border-radius: 0 0 .94vw .94vw;					
			padding: .83vw;
		}
	}

	&_outlined &__dropdown {
		border-top: none;
		border-left: #E2E7F3 1px solid;
		border-right: #E2E7F3 1px solid;
		border-bottom: #E2E7F3 1px solid;
	}

	&_small &__dropdown {
		top: 2.6vw;
		font-size: .78vw;
	}

	&.active &__dropdown {
		visibility: visible;
		z-index: 4;
		opacity: 1;		
	}

	&__item {
		display: flex;
		align-items: center;
		text-decoration: none;
		color: var(--font-dark);
		width: 100%;		
		user-select: none;

		&-logo {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 32px;
			border-radius: 6px;
			background-color: #f1f1f1;
			margin-right: 10px;	

			img {
				width: 24px;
			}
		}

		&-name {
			font-size: inherit;
			transition: all .2s;

			@media (max-width: 768px) {
				font-size: 14px;
				font-weight: 500;
			}
		}

		&:hover &-name {
			color: var(--primary-blue);
		}

		&_active {
			position: relative;
			color: var(--primary-blue);
			
			&::after {
				content: '';
				display: block;
				width: 14px;
				height: 10px;								
				background: url('../img/active-item-icon.svg') no-repeat center;
				background-size: contain;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);

				@media (max-width: 1280px), (min-width: 1920px) {
					width: .73vw;
					height: .52vw;
				}
			}
		}
	}

	&_s {
		.select__button {
			border-radius: .625vw;
			border: #D9EAFF 1px solid;
			height: 1.875vw;
			min-width: 10.52vw;			
			font-size: .94vw;
			padding: 0 2.19vw 0 .83vw;

			&::after {
				right: .94vw;
				top: .78vw;
			}
		}
	}
}