.rankings {
	background-color: #F8F8F8;

	@media (max-width: 1024px) {
		background-color: white;
	}

	&__container {		
		padding: 64px 0 40px;

		@media (max-width: 1024px) {
			padding: 32px 0;
		}
	}

	&__title {
		line-height: 1;
		margin: 0 0 10px;
	}

	&__subtitle {
		margin: 0 0 24px;
	}

	&__filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;

		&-left {
			display: flex;
			width: fit-content;			

			@media (max-width: 1024px) {
				flex: auto;
			}
		}

		&-right {
			display: flex;
			
			@media (max-width: 1024px) {
				flex: none;
			}
		}
	}

	&__search-input {
		background-image: url('../img/search-icon_grey.svg');
		background-repeat: no-repeat;
		background-position: 16px center;
		color: var(--font-dark);
		padding: 0 20px 0 47px;
		margin-right: 24px;

		@media (max-width: 1024px) {
			display: none;
		}
	}

	&__select {
		position: relative;
		width: 100%;
		margin-right: 24px;

		@media (max-width: 1024px) {
			margin-right: 16px;
		}

		&:not(#selectMobile) {
			@media (max-width: 1024px) {
				display: none;
			}
		}

		&#selectMobile {						
			width: 100%;

			@media (min-width: 1025px) {
				display: none;
			}		
		}

		&-button {
			display: flex;
			align-items: center;
			position: relative;
			color: #A0A0A0;
			cursor: pointer;
			width: 14.84vw;
			overflow: hidden;
			padding: 0 30px 0 16px;

			@media (max-width: 1024px) {
				width: 100%;	
			}			
			
			&::after {
				content: '';
				display: block;
				position: absolute;
				right: 24px;
				top: 30px;
				width: 14px;
				height: 8px;
				background: url('../img/dropdown-arrow_blue.svg') no-repeat center;
				transition: all .2s;
				background-size: contain;
				z-index: 3;
			}
			
			&.filled {
				color: var(--font-dark);

				&::before {
					content: '';
					display: block;
					height: 50px;
					width: 70px;
					border-radius: 0 .94vw .94vw 0;
					position: absolute;
					right: 0;
					top: 0;
					background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%, white 100%);
					z-index: 2;
				}

				span {
					display: flex;
					align-items: center;
					justify-content: center;
					flex: none;
					width: 32px;
					height: 32px;
					border-radius: 5px;
					background-color: #F1F1F1;
					font-size: 14px;
					font-weight: 800;
					color: #A0A0A0;
					margin-left: 8px;

					img {
						height: 18px;
					}
				}
			}
		}

		&.active &-button {
			
			&::after {
				transform: rotate(.5turn);
			}
		}

		&-dropdown {			
			position: absolute;
		}		
	}

	&__table {
		display: flex;
		flex-direction: column;		

		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 60px;
			border-radius: 12px 12px 0 0;
			background: #FFFFFF;
			border: 1px solid #EDEDED;
			padding: 0 24px;

			@media (max-width: 1024px) {
				padding: 14px 12px;
			}

			&-item {
				display: flex;
				align-items: center;													
				
				&_num {
					flex: none;
					width: 30px;
					margin-right: 18px;

					@media (max-width: 1200px) {						
						margin-right: 1.5vw;
					}

					@media (max-width: 1024px) {
						width: 24px;
					}
				}

				&_name {
					flex: auto;
					min-width: 220px;
					margin-right: 21px;

					@media (max-width: 1200px) {
						min-width: 18.3vw;
						margin-right: 1.75vw;
					}
				}

				&_avg {
					flex: none;
					width: 124px;
					justify-content: flex-end;
					margin-right: 32px;

					@media (max-width: 1200px) {						
						width: 10.3vw;						
						margin-right: 2.66vw;
					}

					@media (max-width: 1024px) {
						display: none;
					}
				}

				&_volume {
					flex: none;
					width: 124px;
					justify-content: flex-end;
					margin-right: 36px;

					@media (max-width: 1200px) {						
						width: 10.3vw;						
						margin-right: 3vw;
					}

					@media (max-width: 1024px) {
						display: none;
					}
				}

				&_transactions {
					flex: none;
					width: 140px;
					justify-content: flex-end;
					margin-right: 31px;

					@media (max-width: 1200px) {						
						width: 11.67vw;						
						margin-right: 2.6vw;
					}

					@media (max-width: 1024px) {
						display: none;
					}
				}

				&_limits {
					flex: none;
					width: 140px;
					justify-content: flex-end;
					margin-right: 19px;

					@media (max-width: 1200px) {						
						width: 11.67vw;						
						margin-right: 1.58vw;
					}

					@media (max-width: 1024px) {
						display: none;
					}
				}

				&_reviews {
					flex: none;
					width: 111px;
					justify-content: flex-end;

					@media (max-width: 1200px) {						
						width: 9.25vw;												
					}

					@media (max-width: 1024px) {
						display: none;
					}
				}

				&_value {										
					@media (min-width: 1025px) {
						display: none;
					}
				}

				&-info {
					flex: none;
					position: relative;
					width: 17px;
					height: 17px;
					background: url('../img/info-icon.svg') no-repeat center;
					background-size: contain;					
					cursor: pointer;
					margin-right: 6px;

					&-popup {
						z-index: -1;
						opacity: 0;
						transition: all .2s;
						width: 210px;
						font-size: 14px;
						line-height: 1.43;
						transform: translateY(-100%) translateX(-50%);
						left: 50%;
						top: -10px;
					}

					&:hover &-popup {
						z-index: 10;
						opacity: 1;
					}
				}

				&-content {
					font-size: 16px;
					font-weight: 600;
					color: var(--font-dark);
					white-space: nowrap;
					margin-right: 6px;

					@media (max-width: 1200px) {						
						font-size: 1.33vw;
					}

					@media (max-width: 1024px) {
						font-size: 14px;
						font-weight: 800;
					}
				}
			}
		}

		&-row {
			display: flex;
			align-items: center;	
			justify-content: space-between;		
			background-color: #fff;			
			border-left: 1px solid #EDEDED;
			border-right: 1px solid #EDEDED;
			border-bottom: 1px solid #EDEDED;
			padding: 12px 24px;

			@media (max-width: 1024px) {
				padding: 14px 12px;
			}

			&:last-of-type {
				border-radius: 0 0 12px 12px;
			}
		}

		&-item {
			display: flex;	
			align-items: flex-start;
			
			&_num {
				flex: none;
				width: 30px;
				font-size: 18px;
				color: #A3A3A3;
				margin-right: 18px;

				@media (max-width: 1200px) {						
					margin-right: 1.5vw;
				}

				@media (max-width: 1024px) {
					font-size: 14px;
					width: 24px;
				}
			}
			
			&_name {
				flex: auto;			
				min-width: 220px;	
				margin-right: 21px;

				@media (max-width: 1200px) {
					min-width: 18.3vw;
					margin-right: 1.75vw;
				}
			}

			&_avg {
				flex: none;
				width: 124px;
				margin-right: 32px;

				@media (max-width: 1200px) {						
					width: 10.3vw;						
					margin-right: 2.66vw;
				}

				@media (max-width: 1024px) {
					display: none;
				}
			}

			&_volume {
				flex: none;
				width: 124px;
				margin-right: 36px;

				@media (max-width: 1200px) {						
					width: 10.3vw;						
					margin-right: 3vw;
				}

				@media (max-width: 1024px) {
					display: none;
				}
			}

			&_transactions {
				flex: none;
				width: 140px;
				margin-right: 31px;

				@media (max-width: 1200px) {						
					width: 11.67vw;						
					margin-right: 2.6vw;
				}

				@media (max-width: 1024px) {
					display: none;
				}
			}

			&_limits {
				flex: none;
				width: 140px;
				margin-right: 19px;

				@media (max-width: 1200px) {						
					width: 11.67vw;						
					margin-right: 1.58vw;
				}

				@media (max-width: 1024px) {
					display: none;
				}
			}

			&_reviews {
				flex: none;		
				width: 111px;	
				
				@media (max-width: 1200px) {						
					width: 9.25vw;												
				}

				@media (max-width: 1024px) {
					display: none;
				}
			}

			&_value {
				@media (min-width: 1025px) {
					display: none;
				}
			}
			
			&_avg,
			&_volume,
			&_transactions,
			&_limits,
			&_reviews {
				flex-direction: column;
				align-items: flex-end;
			} 
			
			&-content-block {
				display: flex;
				flex-direction: column;
				flex: auto;
			}

			&-title {
				font-family: 'Poppins';
				font-size: 18px;
				font-weight: 600;	
				white-space: nowrap;
				margin: 0;	
				
				@media (max-width: 1200px) {						
					font-size: 1.5vw;												
				}

				@media (max-width: 1024px) {
					font-size: 14px;
					font-weight: 800;
					margin: 0 0 3px;
				}
			}

			&-value {
				font-size: 18px;
				margin-bottom: 2px;

				@media (max-width: 1200px) {						
					font-size: 1.5vw;
				}

				@media (max-width: 1024px) {						
					font-size: 14px;
					font-weight: 600;
				}
			}

			&-description {
				font-size: 14px;
				color: #BDBDBD;

				@media (max-width: 1200px) {						
					font-size: 1.17vw;
				}
			}

			&-rating {
				display: flex;
				align-items: center;
				font-size: 18px;
				font-weight: 800;
				line-height: 1;
				color: #F7D41A;
				background: url('../img/rating-star-icon.svg') no-repeat left center;
				padding-left: 22px;

				@media (max-width: 1200px) {						
					font-size: 1.5vw;
				}
			}

			&-increment {
				font-size: 14px;
				font-weight: 700;
				background: no-repeat left center;				
				padding-left: 20px;

				&_recession {
					color: #D60A0A;
					background-image: url('../img/recession-icon.svg');
				}

				&_growth {
					color: #00A006;
					background-image: url('../img/growth-icon.svg');
				}
			}

			&-subtitle {
				font-size: 12px;
				font-weight: 600;
				color: #BDBDBD;
				margin: 0 0 4px;

				@media (max-width: 1200px) {						
					font-size: 1vw;
				}

				@media (max-width: 1024px) {						
					display: none;
				}
			}
			
			&-logos {
				display: flex;
				position: relative;
				width: fit-content;
				cursor: pointer;

				&::after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					z-index: 2;
				}

				&-popup {
					left: 50%;
					top: -10px;
					transform: translateY(-100%) translateX(-50%);
					min-width: 260px;
					transition: all .2s;
					z-index: -1;
					opacity: 0;

					// &.active {
					// 	z-index: 10;
					// 	opacity: 1;
					// }
				}

				&:hover &-popup {
					z-index: 10;
					opacity: 1;
				} 
			}
			
			&-logo {
				flex: none;
				width: 48px;
				margin-right: 16px;

				@media (max-width: 1024px) {
					width: 32px;
					margin-right: 8px;
				}

				&_middle {
					width: 21px;
				}

				&_small-gray {
					height: 15px;
					width: auto;
					filter: grayscale(100%);
					opacity: .8;

					&:not(:last-of-type) {
						margin-right: 4px;
					}

					@media (max-width: 1024px) {
						height: 13px;
					}
				}
			}
		}
	}
}