@keyframes rotate {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}

.loader-large {
	width: 53px;
	height: 53px;
	background: url('../img/loader-mobile.svg') no-repeat center;
	background-size: contain;
	animation-name: rotate;
	animation: rotate 1.5s linear infinite;
}