.transfer-approve-popup {

	&__token {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 58px 0 36px;

		@media (max-width: 1280px), (min-width: 1920px) {
			margin: 3.02vw 0 1.875vw;
		}
	}

	&__token-logo {
		width: 50px;
		margin-bottom: 10px;

		@media (max-width: 1280px), (min-width: 1920px) {
			width: 2.6vw;
			margin-bottom: .52vw;
		}
	}

	&__token-title {
		font-size: 22px;
		line-height: 1;
		font-weight: 800;
		margin: 0;

		@media (max-width: 1280px), (min-width: 1920px) {
			font-size: 1.15vw;			
		}
	}	
}