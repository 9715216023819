.header {  
  flex: none;  
  position: relative;  
  padding: 0 73px;

  @media (max-width: 1280px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    background: url('../img/header-bg.jpg') no-repeat center bottom;
    background-size: cover;
    padding: 0 20px;
  }

  @media (max-width: 360px) {   
    padding: 0 16px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;      
    width: 100%;
    max-width: 1280px;    
    padding: 0 40px;
    margin: 0 auto;
  
    @media (min-width: 1920px) {
      max-width: 65vw;
      padding: 0;
    }

    @media (min-width: 1920px) {
      max-width: 1920px;      
    }

    @media (max-width: 1280px) {
      max-width: none;
      padding: 0;
    }

    @media (max-width: 768px) {
      max-width: none;
      height: 50px;      
      padding: 0;
    }
  }

  &__content-left {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &_inner {
    display: flex;
    align-items: center;
    height: 76px;

    @media (max-width: 768px) {      
      height: 82px;            
    }

    @media (max-width: 360px) {      
      height: 62px;            
    }
  }

  &__logo {
    width: 188px;

    @media (min-width: 1920px) {
      width: 8.6vw;
    }

    @media (max-width: 1280px) {
      width: 14.7vw;
    }

    @media (max-width: 768px) {
      width: 123px;
    }
  }

  &__menu {
    margin: 0 20px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &_dark &__menu {
    .main-menu__item-link {
      color: white;

      svg {        
        path {
          stroke: white;
        }
      }
    }

    .main-menu__item_soon::after,
    .main-menu__item_new::after {
      background: rgba(44, 45, 84, 0.64);
      color: var(--primary-blue);
    }

    .main-menu__item_disabled {            

      a {
        color: white;       

        &:hover {
          color: white;
        }
      }            
    }
  }

  &__search {
    border-radius: 8px;
    background: #0063DF url('../img/search-icon_dark.svg') no-repeat 17px center;    
    height: 2.7vw;    
    width: 14.95vw;
    border: none;
    outline: none;
    position: relative;
    font-size: 1.04vw;
    font-weight: 600;
    color: white;
    transition: all .2s;
    padding: 0 20px 0 46px; 
    margin-right: 20px;   

    @media (min-width: 1921px) {
      width: 287px;
      font-size: 20px;
      height: 52px;    
    }

    @media (max-width: 768px) {
      display: none;
    }

    &:hover {
      background-color: #22589B;
    }

    &::placeholder {
      color: white;
      opacity: .5;
    }
  }

  &__cta {
    position: relative;

    .btn_middle {
      min-width: 205px;
  
      @media (max-width: 768px) {
        font-size: 12px;
        font-weight: 800;
        height: 32px;  
        min-width: auto;
        border-radius: 8px;
        padding: 0 18px; 
      }
    }
  }

  &__cta.btn_middle {
    min-width: 205px;

    @media (max-width: 768px) {
      font-size: 12px;
      font-weight: 800;
      height: 32px;  
      min-width: auto;
      border-radius: 8px;
      padding: 0 18px; 
    }
  }

  &_dark &__cta.btn_middle {
    background-color: #023898;
  }

  &__menu-button {
    width: 20px;
    height: 14px;
    background: transparent url('../img/menu-icon.svg') no-repeat center;    
    background-size: contain;
    border: none;
    cursor: pointer;

    @media (min-width: 769px) {
      display: none;
    }
  }
}
