.telegram-widget {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 206px;
	background: #FFFFFF;
	box-shadow: 0px 0px 90px rgba(0, 0, 0, 0.1);
	border-radius: 24px;
	position: absolute;
	left: 0;
	top: 0;
	transform: translateY(calc(-100% - 26px));
	z-index: 9999;
	padding: 40px 0 18px;

	&__title {
		text-align: center;
		font-weight: 700;
		font-size: 16px;
		line-height: 1.31;
		color: #202020;
		margin-bottom: 8px;
	}

	&__close {
		position: absolute;
		top: 16px;
		right: 16px;
		width: 13px;
		height: 13px;
		background: transparent url('../img/close-icon_small.svg') no-repeat center;
		border: none;
		background-size: contain;
		cursor: pointer;
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 70px;
		height: 70px;
		border-radius: 50%;
		background-color: var(--primary-blue);
		position: absolute;
		top: -40px;
		left: calc(50% - 35px);		
	}

	&__btn {		
		display: flex;
		align-items: center;
		justify-content: center;
		height: 42px;	
		width: 130px;
		border: 1px solid #EAEEFB;
		border-radius: 10px;
		background: none;
		font-weight: 700;
		font-size: 16px;
		color: #202020;
		cursor: pointer;		
	}
}