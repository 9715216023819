.popup-back-btn {
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 14px;
	font-weight: 700;
	color: var(--primary-blue);
	border: none;
	background: none;
	cursor: pointer;

	&::before {
		content: '';
		width: 5px;
		height: 8px;
		background: url('../img/back-arrow_blue.svg') no-repeat center;
		background-size: contain;
	}
}