.popup-bottom {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;	
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	transition: all .3s;
	
	&.active {
		opacity: 1;
		visibility: visible;
		z-index: 99999;
	}

	&__bg {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(20, 36, 56, 0.85);
		backdrop-filter: blur(15px);
		z-index: 999991;
	}

	&__cnt {
		position: absolute;
		left: 0;
		right: 0;		
		bottom: 0;
		width: 100%;		
		z-index: 999992;
		background-color: white;
		border-radius: 18px 18px 0 0;
		opacity: 0;
		transform: translateY(30%);
		transition: all .3s;
		padding: 24px 20px 40px;
	}

	&.active &__cnt {
		opacity: 1;
		transform: translateY(0);
	}
}