.disclaimer-popup {

	&.popup {
		background: none;
	}

	.popup__container {
		right: 0;
		left: auto;
		top: 0;
		transform: none;
		height: 100vh;
		width: 100%;
		max-width: 545px;
		min-width: auto;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.10);
		padding: 32px 40px;

		@media (max-width: 768px) {
			border-radius: 0 !important;
			padding: 32px 20px;
		}

		&::after {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;

			@media (max-width: 768px) {				
				border-radius: 0 !important;
				width: 100% !important;
				height: 100% !important;
				top: 0 !important;
				left: 0 !important;
			}
		}
		
		& * {
			position: relative;
			z-index: 3;
		}
	}

	&__subtitle {
		font-size: 16px;
		margin: 0 0 20px;
	}

	&__cnt {
		height: calc(100vh - 255px);
		overflow-y: scroll;
		padding-right: 20px;
		margin-bottom: 20px;

		&::-webkit-scrollbar {
			width: 4px;
		}
	
		&::-webkit-scrollbar-track {
			background-color: rgba(0, 113, 255, 0.15);
			width: 4px;
			border-radius: 2px;			
		}
	
		&::-webkit-scrollbar-thumb {
			background-color: var(--primary-blue);
			border-radius: 2px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 20px;
		font-size: 14px;
		line-height: 1.57;
		color: var(--font-dark);		

		p {
			margin: 0;
		}

		ul {
			padding-left: 20px;

			li {
				margin-bottom: 12px;
			}
		}
	}

	&__btn {
		width: 100%;
		border-radius: 8px;
		height: 60px;
		font-size: 16px;		
	}
}