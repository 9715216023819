.popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(20, 36, 56, 0.85);
	// backdrop-filter: blur(32px);
	transition: all .3s;
	visibility: hidden;
	opacity: 0;
	z-index: -1;

	&.active {
		z-index: 999999;
		opacity: 1;
		visibility: visible;
	}

	&__title {
		font-weight: 800;
		font-size: 24px;
		width: fit-content;
		margin: 0 auto 30px auto;
	}
	
	&__container {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		background-color: white;
		border-radius: 24px;
		min-width: 612px;
		min-height: 442px;
		padding: 40px 50px;

		@media (max-width: 768px) {
			width: 100vw;
			min-width: auto;
			min-height: auto;
			transform: none;
			left: 0;
			bottom: 0;
			top: auto;
			border-radius: 12px 12px 0 0;
			padding: 30px 22px 26px;
		}

		&::before {
			display: none;

			@media (max-width: 768px) {
				content: '';
				display: block;
				width: 72px;
				height: 4px;
				border-radius: 2px;
				background: #E2E7F3;
				position: absolute;
				top: 9px;
				left: calc(50% - 36px);
			}
		}
	}

	&_centered &__container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__input-label {
		display: block;
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 20px;
	}

	&__close {
		display: block;
		width: 13px;
		height: 13px;
		background: url('../img/popup-close-button.svg') no-repeat center;
		background-size: contain;
		position: absolute;
		right: 32px;
		top: 28px;
		cursor: pointer;

		// @media (max-width: 768px) {			
		// 	left: 0;
		// 	top: 0;
		// 	right: auto;
		// 	height: 23px;
		// 	width: 100%;
		// 	background: none;
		// }
	}

	&__text {
		font-size: 16px;
		font-weight: 600;
		line-height: 1.5;
		margin: 0;
	}

	&_centered &__text {
		text-align: center;
	}

	&__image {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 107px;
		height: 107px;
		background-color: rgba(0, 113, 255, 0.08);
		border-radius: 50%;
	}

	&__cta {
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		background-color: var(--primary-blue);
		font-weight: 800;
		font-size: 20px;
		border-radius: 16px;
		color: white;
		min-width: 320px;
		height: 72px;		
		cursor: pointer;
		padding: 0 40px;
	}
}