.transfers-form {
	display: flex;
	flex-direction: column;
	border-radius: 1.25vw;	

	&__main {
		display: flex;
		flex-direction: column;
		gap: 16px;			
	}

	&__main-inputs {
		display: flex;		
		flex-direction: column;
		width: 100%;
		gap: 8px;
		position: relative;		
	}

	&__main-input {
		display: flex;		
		flex-direction: column;		
		width: 100%;		
		background: #EFF6FC;
		border: 1px solid #E2E6EE;
		padding: 8px 20px;	

		@media (max-width: 360px) {
			padding: 8px 12px;	
		}

		&_from {			
			border-radius: 16px 16px 0 0;			 
		}

		&_to {			
			border-radius: 0 0 16px 16px;	
		}		
	}	

	&__main-input-name {
		font-size: 14px;
		font-weight: 800;	
	}

	&__main-input-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__main-input-balance {
		// margin-bottom: 8px;
	}

	&__balance-title {
		font-size: 12px;
		color: #9599AD;
	}

	&__balance-value {
		font-size: 12px;
		color: var(--primary-blue);
	}

	&__main-input-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 3px;

		input {
			font-size: 24px;
			font-weight: 500;
			color: var(--font-dark);
			outline: none;
			flex: auto;
			line-height: 1;						
			border: none;
			background-color: transparent;
			padding-right: 20px;

			@media (max-width: 768px) {
				font-size: 16px;
				font-weight: 700;
			}

			&::placeholder {
				color: #D3D5E0;
				opacity: 1;
			}

			&.overbalance {
				color: #E74142;
			}
		}			
	}

	&__equivalent {
		flex: none;
		font-size: 14px;
		color: #989EB1;

		@media (max-width: 768px) {
			font-size: 12px;
		}
	}

	&__submit {		
		width: 100%;		
	}

	&__filters {
		display: flex;
		align-items: center;		
		gap: 32px;	
		border-top: 1px solid rgba(187, 197, 211, 0.32);
		padding-top: 1.3vw;
	}

	&__filters-container {
		display: flex;
		align-items: center;
		gap: 8px;		

		&_wrap {
			flex-wrap: wrap;
		}
	}

	&__filters-item {
		display: flex;
		align-items: center;
	}

	&__filters-title-block {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-right: 9px;

		.info-button {
			margin-right: 5px;
		}
	}
	
	&__filters-title {		
		font-size: 16px;
		font-weight: 500;
		margin-right: 6px;
	}

	&__switch-title {
		font-size: 18px;
		font-weight: 700;
		margin-right: 35px;
	}

	&__advanced-settings {
		display: none;		
		margin-top: 42px;

		&.active {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}				
	}

	&__estimated-token {
		display: flex;
		align-items: center;
		background: rgba(0, 113, 255, 0.15);
		border: 1px solid #0071FF;
		border-radius: 15px;
		height: 32px;
		width: 100%;
		cursor: pointer;
		position: relative;
		padding: 0 20px;
		margin-bottom: 32px;
	}

	&__route-logos {
		display: flex;
		position: relative;
		height: 1.67vw;
		width: var(--route-logos-width);
		margin-right: .83vw;		
		
		img {
			position: absolute;
			top: 0;
			left: 0;
			height: 1.67vw;			
		}
	}

	&__estimated-parameters {
		display: flex;
		align-items: center;
		gap: 14px;
	}

	&__estimated-parameter {
		display: flex;
		align-items: center;
		gap: 4px;
		font-size: 14px;
	}

	&__estimated-token-title {
		display: flex;
		align-items: center;
		font-size: 18px;
		font-weight: 800;
		margin-left: auto;

		&::after {
			content: '';
			width: 6px;
			height: 10px;
			background: url('../img/right-arrow.svg') no-repeat center;
			background-size: contain;
			margin-left: 15px;
		}
	}

	&__estimated-token-badge {
		display: flex;
		align-items: center;
		height: .83vw;
		border-radius: .26vw;
		font-size: .52vw;
		font-weight: 700;
		text-transform: uppercase;
		position: absolute;
		left: 2.24vw;
		top: -.41vw;
		padding: 0 .3125vw;

		&.cheapest {
			background-color: #CCFF00;
		}

		&.fastest {
			background-color: #FF6F32;
		}
	}

	&__collapse-button {
		margin-left: auto;
	}

	&__change-fields-button {
		display: flex;
		align-items: center;
		justify-content: center;
		border: #E2E6EE 1px solid;
		background: white;
		border-radius: 6px;
		width: 34px;
		height: 34px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		cursor: pointer;	

		img {
			width: 18px;
			transform: rotate(.25turn);
		}
	}

	&__main-input-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 26px;
	}
}