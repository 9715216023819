.transfers-table {
	display: flex;
	flex-direction: column;

	&__item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #BBC5D352;
		padding: 30px 0;

		@media (max-width: 1280px), (min-width: 1920px) {
			padding: 1.5625vw 0;
		}
	}

	&__cell {
		flex: 1;
		position: relative;

		&_date {
			flex-basis: 90px;			
			flex: .6;

			@media (max-width: 1280px), (min-width: 1920px) {
				flex-basis: 4.6875vw;
				flex: .7;
			}
		}

		&_actions {
			width: fit-content;
			flex: 0;
		}
	}

	&__cell-title {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(calc(-50% - 50px));
		font-weight: 500;
		font-size: 14px;
		line-height: 1.29;					
		color: #142438;
		opacity: .45;		

		@media (max-width: 1280px), (min-width: 1920px) {
			transform: translateY(calc(-50% - 2.6vw));			
			font-size: .73vw;
		}
	}

	&__item:not(:first-child) &__cell-title {
		display: none;
	}

	&__date {
		font-weight: 500;
		font-size: 14px;
		line-height: 1.29;
		color: #4C76AA;	
		
		@media (max-width: 1280px), (min-width: 1920px) {			
			font-size: .73vw;
		}
	}

	&__token-block {
		display: flex;
		align-items: center;
		position: relative;
	}

	&__token-logos {
		position: relative;
		width: 1.67vw;
		height: 1.67vw;
		margin-right: 8px;
	
		@media (max-width: 1280px), (min-width: 1920px) {
			margin-right: .52vw;
		}
	}

	&__token-logo {
		position: absolute;
		
		&_token {
			top: 50%;
			left: 0;
			width: 28px;
			height: 28px;
			transform: translateY(-50%);
	
			@media (max-width: 1280px), (min-width: 1920px) {
				width: 1.67vw;
				height: 1.67vw;
			}
		}
	
		&_network {
			bottom: -3px;
			right: -.3125vw;
			width: 12px;
			height: 12px;
			border: white 1px solid;
			border-radius: 50%;
	
			@media (max-width: 1280px), (min-width: 1920px) {
				bottom: 0;
				width: .73vw;
				height: .73vw;
			}
		}
	}

	&__token-name {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	&__token {
		font-weight: 800;
		font-size: 14px;
		line-height: 1.7;

		@media (max-width: 1280px), (min-width: 1920px) {
			font-size: .73vw;
		}

		span {
			font-weight: 400;
		}
	}

	&__token-view-link {		
		position: absolute;
		left: 0;
		bottom: -22px;

		@media (max-width: 1280px), (min-width: 1920px) {					
			bottom: -1.15vw;
		}
	}

	&__network {
		font-size: 12px;
		font-weight: 500;
		color: #4C76AA;

		@media (max-width: 1280px), (min-width: 1920px) {
			font-size: .625vw;
		}
	}

	&__via-logos {
		margin-right: 7px;

		@media (max-width: 1280px), (min-width: 1920px) {
			font-size: .36vw;
		}
	}
	
	&__via-block {
		display: flex;
		align-items: center;
	}

	&__via-name {
		display: flex;
		flex-direction: column;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.29;

		@media (max-width: 1280px), (min-width: 1920px) {
			font-size: .73vw;
		}
	}

	&__status {
		display: flex;
		align-items: center;
		gap: 4px;
		font-weight: 600;
		font-size: 14px;
		border-radius: 8px;
		width: fit-content;
		padding: 6px 8px;

		@media (max-width: 1280px), (min-width: 1920px) {
			font-size: .73vw;
			gap: .2vw;						
			border-radius: .42vw;			
			padding: .3125vw .42vw;
		}

		&::before {
			content: '';
			width: 20px;
			height: 20px;
			background: no-repeat center;
			background-size: contain;
			flex-basis: 20px;

			@media (max-width: 1280px), (min-width: 1920px) {
				width: 1.04vw;
				height: 1.04vw;
				flex-basis: 1.04vw;
			}
		}

		&.action-required {
			color: #E4B41E;
			background-color: #FAE0011A;

			&::before {
				background-image: url('../img/status-icons/action-required.svg');
			}
		}

		&.success {
			color: #07B142;
			background-color: #07B1421A;

			&::before {
				background-image: url('../img/status-icons/success.svg');
			}
		}

		&.failed {
			color: #E74142;
			background-color: #E741421A;

			&::before {
				background-image: url('../img/status-icons/failed.svg');
			}
		}
	}

	&__actions {
		display: flex;
		gap: 11px;

		@media (max-width: 1280px), (min-width: 1920px) {
			gap: .57vw;
		}
	}

	&__main-btn {
		font-weight: 700;
		font-size: 16px;
		color: white;
		height: 42px;
		background-color: #142438;
		border: none;
		border-radius: 10px;
		cursor: pointer;
		white-space: nowrap;
		padding: 0 13px;

		@media (max-width: 1280px), (min-width: 1920px) {
			font-size: .83vw;			
			height: 2.1875vw;			
			border-radius: .52vw;			
			padding: 0 .68vw;
		}
	}

	&__remove-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 42px;
		height: 42px;
		border-radius: 10px;
		border: 1px solid rgba(76, 118, 170, 0.35);
		background: none;
		cursor: pointer;

		@media (max-width: 1280px), (min-width: 1920px) {
			width: 2.1875vw;
			height: 2.1875vw;
			border-radius: .52vw;
		}

		img {
			width: 28px;
			height: 28px;

			@media (max-width: 1280px), (min-width: 1920px) {
				width: 1.46vw;
				height: 1.46vw;
			}
		}
	}
}