.success-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	border-radius: 12px;
	background: url('../img/success-bg.png') no-repeat center;
	background-size: cover;
	padding: 10px 10px 12px;

	&__title {
		font-size: 12px;
		line-height: 1;
	}

	&__value {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4px;

		&-main {			
			font-weight: 800;
			font-size: 18px;
			line-height: 1;

			span {
				font-size: 12px;
				font-weight: 400;
				line-height: 1;
			}
		}

		&-secondary {
			font-size: 12px;		
			line-height: 1;
			letter-spacing: 0.15em;			
			color: #10B57E;
		}
	}

	&__links {
		display: flex;
		align-items: center;
		gap: 6px;

		a {
			display: flex;
			align-items: center;
			gap: 6px;
			font-weight: 600;
			font-size: 11px;
			color: #142438;

			@media (max-width: 360px) {
				font-size: 9px;
			}
			
			&:not(:first-child)::before {
				content: '';
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background-color: #142438;
			}
		}
	}

	&__path {
		display: flex;
		align-items: center;
		gap: 7px;

		&-item {
			display: flex;
			align-items: center;
			gap: 4px;
			font-size: 12px;

			&:not(:first-of-type) {
				&::before {
					content: '';
					width: 3px;
					height: 3px;
					border-radius: 50%;
					background-color: #142438;
					margin-right: 3px;
				}
			}
		}

		&-icon {
			height: 14px;
		}

		&-network {
			display: flex;
			align-items: center;
			gap: 4px;

			&:not(:first-of-type) {
				margin-left: 2px;
			}

			img {
				width: 19px;
			}
		}
	}
}