.steps-list {
	display: flex;
	flex-direction: column;
	gap: 20px;
	counter-reset: olCounter;
	padding: 0;
	margin: 0;

	li {
		list-style: none;
		position: relative;
		font-size: 16px;
		line-height: 1.56;
		padding-left: 38px;

		&::before {
			counter-increment: olCounter;
			content: counter(olCounter);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: #E2EBF6;
			font-size: 16px;
			font-weight: 700;
			color: #2F8AF5;
			position: absolute;
			left: 0;
			top: -3px;
		}

		p {
			margin: 0;

			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
}