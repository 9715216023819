.swap-details {
	display: flex;
	flex-direction: column;
	border: 1px solid #2686FF;
	border-radius: 15px;
	position: relative;

	&::before {
		content: 'Best';
		display: flex;
		align-items: center;
		height: 18px;
		width: fit-content;
		border-radius: 6px;
		background-color: #CCFF00;
		font-weight: 700;
		font-size: 10px;
		line-height: 1;
		text-transform: uppercase;
		position: absolute;
		left: 22px;
		top: -9px;
		padding: 0 7px;
	}

	&_loading {		
		align-items: center;
		justify-content: center;
		height: 118px;

		&::before {
			display: none;
		}
	}

	&_no-routes {		
		align-items: center;
		justify-content: center;
		border-color: #E2E6EE;
		height: 118px;

		&::before {
			display: none;
		}

		img {
			margin-bottom: 5px;
		}

		h3 {
			font-weight: 800;
			font-size: 16px;
			margin: 0 0 5px;
		}

		p {
			font-weight: 600;
			font-size: 14px;
			line-height: 1.29;						
			color: #AFBBC2;
			margin: 0;
		}
	}

	&__main {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgba(187, 197, 211, 0.32);
		padding: 14px 14px 10px 22px;

		@media (max-width: 768px) {
			flex-direction: column;
			align-items: flex-start;
			border-bottom: none;
			padding: 0;
		}
	}

	&__target {
		display: flex;
		align-items: center;
		gap: 7px;

		@media (max-width: 768px) {			
			border-bottom: 1px solid rgba(187, 197, 211, 0.32);
			width: 100%;
			padding: 20px;
		}

		@media (max-width: 360px) {					
			padding: 12px;
		}
	}

	&__target-title {
		font-weight: 800;
		font-size: 18px;
		line-height: 1;	
		color: #142438;
	}

	&__target-details {
		display: flex;
		align-items: center;
		gap: 6px;
		font-size: 12px;
		line-height: 1;	
		color: #9599AD;

		span {
			display: flex;
			align-items: center;
			gap: 6px;
			
			&:not(:first-child)::before {
				content: '';
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background: #E2E6EE;
			}		
		}
	}

	&__target-icon {
		width: 26px;
	}

	&__target-caption {
		display: flex;
		flex-direction: column;
		gap: 3px;
	}

	&__info {
		display: flex;
		align-items: center;
		gap: 8px;		

		@media (max-width: 768px) {
			padding: 16px 20px 0 20px;
		}

		@media (max-width: 360px) {
			padding: 16px 12px 0 12px;
		}

		&-item {
			display: flex;
			align-items: center;
			gap: 4px;
			position: relative;
		}

		&-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			// width: 24px;			
			// border: 1px solid #E2E6EE;
			// border-radius: 4px;

			img {
				height: 16px;
			}
		}

		&-caption {
			font-size: 14px;
			line-height: 1;						
			color: #000000;
		}
	}

	&__path {
		display: flex;
		align-items: center;
		gap: 14px;
		padding: 14px 22px 17px 22px;

		@media (max-width: 768px) {
			gap: 10px;
		}

		@media (max-width: 360px) {
			padding: 14px 12px 17px 12px;
		}

		&-item {
			display: flex;
			align-items: center;			
			font-size: 12px;
			line-height: 1;						
			color: #9599AD;

			&:not(:first-child)::before {
				content: '';
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background: #E2E6EE;
				margin-right: 14px;

				@media (max-width: 768px) {
					margin-right: 10px;
				}
			}
		}
	}
}