.switch-network-popup {
	background: white;
	border-radius: 12px 12px 0 0;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 90vh;	
	transition: all .3s;
	visibility: hidden;
	opacity: 0;
	z-index: -1;

	&.active {
		z-index: 99999999;
		opacity: 1;
		visibility: visible;
	}

	&__header {
		display: flex;
		align-items: center;	
		justify-content: center;	
		height: 70px;
		position: relative;
		padding: 0 20px;
	}

	&__title {
		font-size: 20px;
		margin: 0;
	}

	&__back {
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	&__close {
		width: 13px;
		height: 13px;
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		border: none;
		background: url('../img/close-icon_black.svg') no-repeat center;
		background-size: contain;
		cursor: pointer;
	}

	&__networks-list {
		display: flex;
		flex-direction: column;
		gap: 14px;
		height: calc(100vh - 70px);
		overflow-y: scroll;
		padding: 0 20px;

		&::-webkit-scrollbar {
			width: 4px;
		}
	
		&::-webkit-scrollbar-track {
			background-color: rgba(0, 113, 255, 0.15);
			width: 4px;
			border-radius: 2px;
			margin-bottom: 40px;
		}
	
		&::-webkit-scrollbar-thumb {
			background-color: var(--primary-blue);
			border-radius: 2px;
		}
	}
}