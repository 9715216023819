.filters-mobile-panel {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: white;
	z-index: -1;
	visibility: hidden;
	opacity: 0;
	transform: translateX(50%);
	transition: all .25s;
	padding: 16px 20px;

	&.active {
		z-index: 9999;
		visibility: visible;
		opacity: 1;
		transform: translateX(0);
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 48px;
	}

	&__title {
		margin: 0;
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 32px;
	}

	&__reset-btn {
		font-style: 14px;
		font-weight: 700;
		color: #142438;
		opacity: .45;
		border: none;
		background: none;
		cursor: pointer;
		padding: 0;
	}
}