.claim-btn {
	display: flex;
	align-items: center;
	gap: 4px;
	height: 32px;
	border-radius: 6px;
	background-color: black;
	font-size: 13px;
	font-weight: 800;
	color: white;
	border: none;
	cursor: pointer;
	padding: 0 10px 0 8px;

	&:disabled {
		background: #AFBBC2;
		cursor: auto;
	}

	&.tooltip-cnt:not(&:disabled) .tooltip-popup {
		display: none;
	}

	.tooltip-popup {
		min-width: 120px;
		width: max-content;
		text-align: left;		
		font-weight: 400;

		@media (max-width: 768px) {
			top: 0;
			left: 50px;
			transform: translateX(-100%) translateY(calc(-100% - 8px));
		}

		&::after {			
			@media (max-width: 768px) {
				left: auto;
				right: 12px;	
			}			
		}
	}
}