.nav-link{
  cursor: pointer;
}
.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background:  rgba(255, 255, 255, 0);
  z-index: 100;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #000;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
  
  @keyframes spin {
  100% {
    transform: rotate(360deg);
  }
  }
  .transfers-form__main-input-block input{
    min-width: 150px;
  }
  .transfers-form__main-input-block span{
    bottom:-15px;
    left:0px
  }
  
  
  .pulse-button {
    position: relative;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(20, 36, 56, 0.5);
    animation: pulse 1.5s infinite;
  }
  
  .pulse-button:hover {
    animation: none;
  }
  
  @keyframes pulse {
    0% {
      -moz-transform: scale(0.9);
      -ms-transform: scale(0.9);
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
    95% {
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
      box-shadow: 0 0 0 15px rgba(#5a99d4, 0);
    }
    100% {
      -moz-transform: scale(0.98);
      -ms-transform: scale(0.98);
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
      box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
  }

  .risk-info{
    opacity: 0.7;
    font-size: 12px;
    text-align: center;
  }
.error_block{
  font-size: 9px;
  color: red;
}
  // .estimated-token__route-logos{
  //   margin-right: 1.6vw;
  // }
  .balanceWarning{
    position: relative;
    display: block;
    height: 20px;
  }
  .balanceWarning .error-block{
    left: 0;
    top: 0;
  }

  /**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #020202;
  color: #020202;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #020202;
  color: #020202;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #020202;
  color: #020202;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #020202;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.svg-pi-wrapper {
  position: relative;
}

.svg-pi {
  transform: rotate(-90deg); /* Fix the orientation */
}

/* Animated spinner version */
.svg-pi-indicator--spinner {
  animation: spinner .75s linear infinite;
  transform-origin: center;
}

.svg-pi-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.svg-pi-label__loading {
  opacity: .5;
  font-size: 0.75em;
}

.svg-pi-label__progress {
  font-size: 1.5em;
  font-weight: bold;
}

.svg-pi-label__loading,
.svg-pi-label__progress {
  display: block;
}

/* Spinner animation */
@keyframes spinner {
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(360deg)
  }
}

.cirleWrap{
  display: block;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.alert__close{
  display: block;
    width: 13px;
    height: 13px;
    background: url('../img/popup-close-button.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    right: 5px;
    top: 0px;
    cursor: pointer;
}

.webp .transfers_proccess::after, .webp .transfers_success::after, .webp .transfers_warning::after { background: url(../img/gradient-cap.webp) no-repeat center; }

// .webp .success-message { background: url(../img/success-bg.webp) no-repeat center; }

.webp .asterizm__form-header { background: url(../img/asterizm-form-header-bg.webp) no-repeat center; }

.webp .container { background: url(../img/page-bg.webp) no-repeat center top; }

.webp .container_dark { background: #000212 url(../img/cnt-bg-dark.webp) no-repeat center top; }

@media (max-width: 768px) {

  .webp .header { background: url(../img/header-bg.webp) no-repeat center bottom; }
}
.transfers-container__subscription{
  background-color: unset;
}
.dot {
  height: 5px;
  width: 5px;
  // background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dot-success{
  background-color: #6ee73c;
}

.dot-warning{
  background-color: #e9e91e;
}

.choose-token-popup__source-tokens{
  overflow-x: hidden;
}

.alert{
  top:20px;
  bottom: unset!important;
}

body > iframe {
  display: none;
}