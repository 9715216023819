.switch {
	
	&__input {
		visibility: hidden;
		z-index: -1;
		width: 1px;
		height: 1px;
		position: absolute;
	}

	&__block {
		display: flex;
		width: 44px;
		height: 24px;
		border-radius: 12px;		
		border: #7A9DB1 1px solid;
		position: relative;
		cursor: pointer;
		padding: 2px;

		@media (max-width: 1280px), (min-width: 1920px) {
			width: 2.29vw;
			height: 1.25vw;
			border-radius: .625vw;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 1px;
			top: 1px;
			width: 20px;
			height: 20px;
			border-radius: 50%;			
			background-color: #7A9DB1;
			transition: all .2s;

			@media (max-width: 1280px), (min-width: 1920px) {
				width: calc(1.25vw - 4px);
				height: calc(1.25vw - 4px);
			}
		}
	}

	&__input:checked + &__block {
		background-color: var(--primary-blue);
		border: var(--primary-blue) 1px solid;

		&::after {
			right: 1px;			
			left: auto;
			background-color: #fff;
		}
	}
}