.transfers {
	position: relative;		
	min-width: 500px;	
	border-radius: 24px;
	background-color: #fff;
	box-shadow: 0px 0px 90px rgba(0, 0, 0, 0.1);		
	padding: 27px;		
	margin-bottom: 24px;

	@media (max-width: 768px) {				
		width: 100%;
		min-width: auto;
		padding: 25px;		
	}		

	@media (max-width: 420px) {
		padding-top: 12px;
	}

	@media (max-width: 360px) {						
		padding: 25px 16px;		
	}		

	&::before {
		content: '';
		width: calc(100% - 4px);
		height: calc(100% - 4px);
		border-radius: 22px;
		background: white;
		position: absolute;
		left: 2px;
		top: 2px;
		z-index: 1;
	}

	&_proccess,
	&_success,
	&_warning {
		min-height: 384px;
		max-width: 500px;	
		padding: 20px 20px 50px;		

		@media (max-width: 768px) {
			width: calc(100% - 2px);
			max-width: none;	
		}

		@media (max-width: 420px) {
			padding: 20px 16px 50px;		
		}
		
		&::after {
			content: '';
			width: 17px;
			height: 130px;
			background: url('../img/gradient-cap.png') no-repeat center;
			background-size: contain;
			position: absolute;
			top: 62px;
			right: 0;
			z-index: 3;

			@media (max-width: 768px) {
				display: none;
			}
		}
	}
	
	&_proccess {		
		background: linear-gradient(199.65deg, #50CAFF 8.77%, #4EC8FF 60.57%, #0071FF 72.91%, #0071FF 87.02%);
		box-shadow: 0px 0px 20px rgba(22, 118, 239, 0.41);		

		&::before {
			box-shadow: inset 0px 0px 20px rgba(22, 118, 239, 0.30);
		}		
	}

	&_success {
		background: linear-gradient(313.84deg, #2FD696 24.78%, #75EEAE 78.23%);
		box-shadow: 0px 0px 20px rgba(27, 207, 143, 0.4);

		&::before {
			box-shadow: inset 0px 0px 20px rgba(27, 207, 143, 0.4);
		}
	}

	&_warning {
		background: #F4DE8E;
	}

	&__title {
		font-weight: 800;
		font-size: 24px;
		margin: 0;

		@media (max-width: 768px) {
			font-size: 20px;
		}
	}

	&__title-block {
		display: flex;
		align-items: center;
		gap: 5px;		
		line-height: 1;
	}

	&__trx-id {
		display: flex;
		align-items: center;
		border: 1px solid #EAEEFB;
		border-radius: 8px;
		font-weight: 600;
		font-size: 11px;
		height: 24px;
		position: relative;
		bottom: -2px;
		padding: 0 8px;

		span:first-of-type {
			color:#9599AD;
		}

		.copy-btn {
			margin-left: 5px;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;
	}

	&__swaping-tokens {
		display: flex;
		align-items: center;
		gap: 11px;
		margin-bottom: 16px;
	}

	&__indicator {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 5px;
		flex: none;

		&-networks {
			display: flex;
			align-items: center;
			gap: 8px;

			img {
				width: 14px;
				height: 14px;
				border-radius: 50%;
				flex: none;
			}
		}
	}
	
	&__arrow {
		width: 24px;
		height: 16px;
		flex: none;
	}

	&__subtitle {
		font-size: .9375vw;
		font-weight: 800;
		line-height: 2.33;
		margin-bottom: .9375vw;
	}

	&__form-head {
		display: flex;
		align-items: center;
		padding: 0 2.4vw .73vw 2.4vw;

		&-title {
			font-weight: 700;
			color: white;
			font-size: 1.25vw;
		}

		&-from {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 20.83vw;			
			margin-right: .26vw;
		}

		&-to {
			display: flex;
			align-items: center;
			padding-left: .625vw;
		}
	}

	&__content {		
		position: relative;		
		z-index: 10;
	}

	&__supported {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 26px;

		@media (max-width: 420px) {
			margin-bottom: 12px;
		}
	}

	&__supported-title {
		font-size: 14px;
		font-weight: 700;
		color: #142438;		
		margin-right: 5px;	
		
		@media (max-width: 420px) {
			max-width: 230px;
			line-height: 1.43;
		}

		span {
			color: var(--primary-blue);
		}
	}
	
	&__supported-item-title {
		font-size: 14px;
		font-weight: 500;
		opacity: .45;
		margin-right: 5px;
	}

	&__supported-list {
		display: flex;
		align-items: center;
		gap: 16px;
	}

	&__supported-item {
		display: flex;
		align-items: center;
	}

	&__supported-logos {
		margin-right: 3px;
	}

	&__supported-logos-more {
		font-size: 12px;
		font-weight: 700;
		color: var(--primary-blue);
		text-decoration: none;
	}

	&__tabs {
		display: flex;
		flex-direction: column;		
		position: absolute;
		right: 0;
		top: 90px;
		transform: translateX(100%);		
		border-radius: 0 16px 16px 0;
		z-index: 1;
		background: linear-gradient(90deg, #F2F2F2 0%, #F8F8F8 20.83%, #FEFEFE 100%);

		@media (max-width: 768px) {
			flex-direction: row;
			justify-content: center;
			gap: 63px;
			position: fixed;
			bottom: 0;
			left: 0;
			top: auto;			
			border-radius: 0;
			transform: none;
			width: 100%;
			height: 60px;
			background: white;
			z-index: 99999;
			box-shadow: 0px 3.47899px 39.1387px rgba(0, 0, 0, 0.12);
			padding-top: 2px;
		}

		&::before,
		&::after {
			content: '';		
			display: block;	
			width: 8px;
			height: 8px;
			background: url('../img/soft-corner-icon.svg') no-repeat left top;
			background-size: contain;
			position: absolute;		
			
			@media (max-width: 768px) {
				display: none;
			}
		}
		
		&::before {			
			transform: rotate(-90deg);			
			left: 0;
			top: -8px;
		}

		&::after {						
			left: 0;
			bottom: -8px;
		}
	}

	&__tab {		
		text-decoration: none;
		font-weight: 700;
		font-size: 12px;		
		color: #292D32;		
		transition: .1s all;			
		position: relative;
		padding: 6px;

		@media (max-width: 768px) {
			padding: 0;
		}
		
		span {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 5px;
			width: 55px;
			height: 55px;
			border: 1px solid #EAEEFB;
			border-radius: 10px;

			@media (max-width: 768px) {
				border: none;
			}
		}

		svg {
			transition: .1s all;
		}

		&:hover {
			color: var(--primary-blue);			

			svg {
				fill: var(--primary-blue);
			}

			span {
				background-color: #EBF5FF;
				border-color: #EBF5FF;

				@media (max-width: 768px) {
					background: none;
					border: none;
				}
			}
		}

		&.active {
			color: white;
			border-radius: 0 18px 18px 0;
			box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.04);				
			background-color: #fff;
			position: relative;

			@media (max-width: 768px) {
				color: var(--primary-blue);
				box-shadow: none;
			}

			&::before,
			&::after {
				content: '';		
				display: block;	
				width: 8px;
				height: 8px;
				background: url('../img/soft-corner-icon_white.svg') no-repeat left top;
				background-size: contain;
				position: absolute;			
			}
			
			&::before {			
				transform: rotate(-90deg);			
				left: 0;
				top: -8px;

				@media (max-width: 768px) {
					transform: none;
					top: -4px;
					left: calc(50% - 19px);					
					width: 38px;
					height: 8px;
					background: url('../img/tab-active-mobile.svg') no-repeat center;
				}
			}

			&::after {						
				left: 0;
				bottom: -8px;

				@media (max-width: 768px) {
					display: none;
				}
			}

			svg {
				fill: white;

				@media (max-width: 768px) {
					fill: var(--primary-blue);
				}
			}

			span {
				background-color: var(--primary-blue);

				@media (max-width: 768px) {
					background-color: white;
				}
			}
		}
	}

	&__notification {
		display: flex;
		align-items: center;
		font-weight: 600;
		font-size: 14px;
		line-height: 1.3;
		min-height: 46px;
		border-radius: 12px;
		padding: 8px 12px;

		&:not(:last-child) {
			margin-bottom: 8px;
		}

		&_success {
			background: #EBFFF4;	
			position: relative;
			padding: 12px 12px 12px 42px;		

			&::before {
				content: '';
				width: 24px;
				height: 24px;
				background: url('../img/success-icon.svg') no-repeat center;
				background-size: contain;
				position: absolute;
				left: 12px;
				top: 11px;
			}
		}

		&_attention {
			display: flex;
			align-items: flex-start;
			gap: 7px;
			background: #FEF7EE;
			border: #F2C005 2px solid;
			font-weight: 500;

			&::before {
				content: '';
				width: 24px;
				height: 24px;
				background: url('../img/info-message-icon.svg') no-repeat center;
				background-size: contain;
				flex: none;
			}
		}

		&_proccess {
			display: flex;
			align-items: center;
			gap: 8px;
			position: relative;
			background: linear-gradient(135deg, #50CAFF 0%, #0071FF 100%);

			&::before {
				content: '';
				width: calc(100% - 4px);
				height: calc(100% - 4px);
				background-color: #EBF5FF;
				border-radius: 10px;
				position: absolute;
				left: 2px;
				top: 2px;
				z-index: 1;
			}

			span {
				position: relative;
				z-index: 2;
			}
		}

		&-loader {
			position: relative;
			width: 20px;
			flex: none;
			z-index: 2;
		}
	}

	&__telegram-link {
		display: flex;
		align-items: center;
		gap: 3px;
		font-weight: 600;
		font-size: 11px;
		line-height: 1.27;	
		text-decoration: underline;
		white-space: nowrap;
		color: #0071FF;
		position: absolute;
		left: 50%;
		bottom: 20px;
		z-index: 10;
		transform: translateX(-50%);

		@media (max-width: 768px) {
			width: fit-content;
		}
	}

	&__banner {
		width: 100%;
		margin-bottom: 4px;
	}
}