.filter-switch {
	display: flex;
	height: 2.7vw;
	border-radius: .94vw;
	background-color: white;
	box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.02);

	@media (max-width: 768px) {
		width: 100%;
		height: 52px;
		border-radius: 18px;
	}

	&_blue {
		box-shadow: none;
		background-color: #F4F6FB;
	}

	&__item {
		@media (max-width: 768px) {
			flex: auto;
		}
	}

	&__input {
		position: absolute;
		visibility: hidden;
		z-index: -1;
		width: 1px;
		height: 1px;
		outline: none;
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: .94vw;
		font-weight: 600;
		color: var(--font-dark);		
		height: 2.7vw;
		min-width: 4.2vw;
		border-radius: .94vw;	
		cursor: pointer;
		transition: all .2s;
		padding: 0 1.04vw;		

		@media (max-width: 768px) {
			font-size: 15px;
			height: 52px;
			width: 100%;
			border-radius: 18px;
		}

		&:hover {
			color: var(--primary-blue);
		}
	}

	&__input:checked + &__button {
		background-color: var(--primary-blue);
		color: white;		
		cursor: default;
	}
}