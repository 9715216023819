.claim-widget {
	display: flex;
	align-items: center;	
	gap: 5px;
	background: linear-gradient(to bottom, #11A97D 0%, #326CAB 100%);
	border-radius: 10px;
	position: relative;
	color: #2D6DA1;
	font-size: 13px;
	font-weight: 600;
	width: 100%;
	padding: 6px 6px 6px 10px;
	margin-bottom: 16px;

	@media (max-width: 768px) {
		width: calc(100% - 40px);
		margin: 0 20px 20px;
	}

	&::before {
		content: '';
		border-radius: 9px;
		background: #DFF0EE;
		position: absolute;
		top: 1px;
		bottom: 1px;
		right: 1px;
		left: 1px;
		z-index: 1;
	}

	& > * {
		position: relative;
		z-index: 2;
	}

	&__btn {
		margin-left: auto;
		transition: background .2s;

		&:hover {
			background: #28254F;
		}
	}

	&__success-icon {
		width: 24px;
	}

	&__info {
		width: 20px;
		height: 20px;
		background: none;
		border: none;
		padding: 0;

		.tooltip-popup {
			width: 252px;
			text-align: left;		
			font-weight: 400;			

			ol {
				margin: 0;
				padding-left: 16px;
			}
		}
	}	
}