.content-tab {
	flex: auto;	
	
	@media (max-width: 768px) {
		width: 100%;
	}

	&__input {
		position: absolute;
		visibility: hidden;
		width: 1px;
		height: 1px;
		appearance: none;
		z-index: -1;
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 3.75vw;
		border-radius: .73vw;
		font-size: 1.46vw;
		font-weight: 800;
		cursor: pointer;
		transition: all .2s;

		@media (max-width: 768px) {
			width: 100%;
			height: 40px;
			font-size: 16px;
			border-radius: 14px;
		}

		&:hover {
			color: var(--primary-blue);
		}
	}

	&__input:checked + &__button {
		background-color: var(--primary-blue);
		color: white;
	}
}