.search-popup {
	
	.popup__container {
		top: 0;
		transform: translateX(-50%) translateY(-100%);
		border-radius: 0 0 1.25vw 1.25vw;
		width: 65vw;		
		transition: all .4s;
	}

	&.active .popup__container {
		transform: translateX(-50%) translateY(0);
	}

	&__input-block {
		display: flex;
		align-items: center;
		gap: .83vw;
	}

	&__input {
		height: 3.125vw;
		width: 29.17vw;
		border-radius: .83vw;
		border: #D5D5D6 1px solid;
		background: url('../img/search-icon_blue.svg') no-repeat 1.09vw center;
		background-size: 1.25vw;
		font-size: .9375vw;
		font-weight: 600;
		outline: none;
		padding: 0 1.09vw 0 3.02vw;		

		&::placeholder {
			color: #D5D5D6;			
			opacity: 1;
		}
	}

	&__button {
		height: 3.125vw;
		border-radius: .83vw;
		background-color: #142438;
		font-size: .9375vw;
		font-weight: 800;
		color: white;
		border: none;
		transition: all .2s;
		cursor: pointer;
		padding: 0 2.5vw;

		&:hover {
			background-color: #0A3468;
		}
	}

	&__no-results {
		margin-top: 1.67vw;

		h2 {
			font-size: .78vw;
			font-weight: 700;
			color: #0A2533;
			margin: 0 0 .625vw;
		}

		span {
			font-size: .78vw;
			font-weight: 700;
			color: #9599AD;
			line-height: 1.6;
		}
	}

	&__results {
		margin-top: 1.67vw;
	}
	
	&__results-title {
		font-size: .73vw;
		font-weight: 800;
		color: #989EB1;
		margin: 0 0 .47vw;
	}
	
	&__results-list {
		display: flex;
		flex-direction: column;
		width: calc(100% + .52vw);
		position: relative;
		left: -.52vw;
		padding: 0;
		margin: 0;
	}

	&__results-item {
		display: flex;
		align-items: center;
		gap: .42vw;
		list-style: none;
		height: 2.4vw;
		border-radius: .52vw;
		transition: all .2s;
		cursor: pointer;	
		font-size: .83vw;
		font-weight: 600;	
		padding: 0 .52vw;

		&:hover {
			background-color: #F8FAFD;
		}

		img {
			width: 1.67vw;
		}
	}
}