.history {
	position: relative;		
	width: 470px;
	min-height: 400px;
	border-radius: 24px;
	background-color: #fff;
	box-shadow: 0px 0px 90px rgba(0, 0, 0, 0.1);		
	padding: 0 20px 20px;		
	margin: 0 auto 24px;

	@media (max-width: 768px) {		
		max-width: 470px;
		width: 100%;
		padding: 0 15px 20px;		
	}		

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding: 20px 0 24px;

		.back-btn {
			position: absolute;
			left: 0;
			top: 25px;
		}
	}

	&__title {
		font-weight: 800;
		font-size: 24px;
		line-height: 1;
		width: fit-content;
		margin: 0;

		@media (max-width: 768px) {
			font-size: 20px;
		}

		span {
			color: var(--primary-blue);
		}
	}

	&__section {

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 14px;
		}

		&-title {
			font-weight: 600;
			font-size: 16px;
			line-height: 1;			
			color: #9599AD;
			margin: 0;

			@media (max-width: 768px) {
				font-size: 14px;
			}
		}
	}

	&__items {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	&__item {
		border: #EAEEFB 1px solid;
		border-radius: 12px;
		position: relative;
		padding: 8px 8px 8px 12px;		

		// &_completed,
		// &_rejected,
		// &_pending {
		// 	&::after {
		// 		content: '';
		// 		width: 18px;
		// 		height: 18px;				
		// 		background-size: contain;
		// 		position: absolute;
		// 		top: 8px;
		// 		right: 8px;
		// 	}
		// }
		
		// &_completed {					
		// 	&::after {				
		// 		background: url('../img/completed-icon.svg') no-repeat center;				
		// 	}
		// }

		// &_rejected {
		// 	&::after {				
		// 		background: url('../img/rejected-icon.svg') no-repeat center;				
		// 	}
		// }

		// &_pending {
		// 	&::after {				
		// 		background: url('../img/alert-icon_yellow.svg') no-repeat center;				
		// 	}
		// }

		&-header {
			display: flex;
			align-items: center;
			gap: 6px;
			font-weight: 500;
			font-size: 12px;
			margin-bottom: 10px;

			span {
				display: flex;
				align-items: center;
				gap: 6px;
				
				&:not(:first-child)::before {
					content: '';
					width: 4px;
					height: 4px;
					border-radius: 50%;
					background: rgba(149, 153, 173, 0.45);
				}
			}
		}

		&-id span {
			display: flex;
			align-items: center;			
			height: 18px;
			font-weight: 800;
			font-size: 10px;
			line-height: 1;		
			color: #142438;
			background: #EFF2F5;
			border-radius: 6px;
			padding: 0 5px;
		}

		&-content {
			display: flex;
			align-items: center;
			gap: 12px;
		}

		&-alert {
			display: flex;
			align-items: center;
			gap: 4px;
			height: 20px;
			font-weight: 600;
			font-size: 12px;
			line-height: 1;	
			color: #F2B705;
			background: rgba(242, 183, 5, 0.15);
			border-radius: 6px;
			position: absolute;
			right: 8px;
			top: 8px;
			padding: 0 5px;
		}

		.token-cnt {
			flex: none;
			width: fit-content;
			background: none;
			padding: 0;
		}
	}

	&__empty-cnt {
		display: flex;
		flex-direction: column;
		align-items: center;		
		margin: 50px 0 80px;

		img {
			margin-bottom: 12px;
		}
	}

	&__empty-title {
		font-weight: 800;
		font-size: 16px;
		line-height: 1.5;	
		color: #142438;
		margin: 0 0 4px;
	}

	&__empty-description {
		font-weight: 600;
		font-size: 16px;
		line-height: 1.5;
		text-align: center;
		color: #9599AD;
		width: 70%;
		margin: 0 0 20px;
	}

	&__label {
		position: absolute;
		top: 8px;
		right: 8px;
	}
}