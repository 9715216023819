.faq {
	position: relative;		
	width: 690px;
	border-radius: 24px;
	background-color: #fff;
	box-shadow: 0px 0px 90px rgba(0, 0, 0, 0.1);		
	padding: 0 12px 54px 0;		
	margin: 0 auto 24px;

	@media (max-width: 768px) {		
		max-width: 690px;
		width: 100%;
	}		

	&__header {
		display: flex;
		justify-content: center;
		padding: 32px 12px 16px;
	}

	&__title {
		font-weight: 800;
		font-size: 24px;
		line-height: 1;
		width: fit-content;
		margin: 0;

		@media (max-width: 768px) {
			font-size: 20px;
		}

		span {
			color: var(--primary-blue);
		}
	}

	&__container {
		height: calc(100vh - 202px);
		overflow-y: scroll;
		padding-right: 14px;

		@media (max-width: 360px) {
			height: calc(100vh - 190px);
		}

		&::-webkit-scrollbar {
			width: 4px;
		}
	
		&::-webkit-scrollbar-track {
			background-color: rgba(0, 113, 255, 0.15);
			width: 4px;
			border-radius: 2px;
			margin-bottom: .98vw;
		}
	
		&::-webkit-scrollbar-thumb {
			background-color: var(--primary-blue);
			border-radius: 2px;
		}
	}

	&__item {		

		&-header {
			display: flex;
			align-items: center;			
			padding: 20px 35px;
			position: relative;
			cursor: pointer;

			@media (max-width: 360px) {
				padding: 12px 30px 12px 20px;
			}

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 10;
			}
		}

		&-close {
			position: absolute;
			right: 16px;
			top: 24px;
			width: 24px;
			height: 24px;
			border: none;
			background: url('../img/faq-close-icon.svg') no-repeat center;
			background-size: contain;
			cursor: pointer;
			transition: .3s all;

			@media (max-width: 768px) {
				right: 0;
			}

			@media (max-width: 360px) {
				top: 12px;
			}
		}

		&.active &-close {
			transform: rotate(45deg);
		}

		&-title {
			font-weight: 700;
			font-size: 18px;
			line-height: 1.56;
			transition: .1s all;
			margin: 0;		
			
			@media (max-width: 768px) {
				font-size: 15px;
			}
		}

		&-header:hover &-title {
			color: var(--primary-blue);
		}

		&-content {
			display: none;
			font-weight: 600;
			font-size: 16px;
			line-height: 1.75;		
			color: #52648E;
			padding: 0 24px 24px 35px;
			margin: 0;

			@media (max-width: 768px) {
				font-size: 14px;
			}
		}

		&.active &-content {
			display: block;
		}
	}
}