.tokens-list-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;	

	&:not(:last-child) {
		border-bottom: 1px solid #F4F6FB;
		padding-bottom: 16px;

		@media (max-width: 768px) {
			border: none;
			padding: 0;
		}
	}

	&__left {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	&__right {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	&__logo {		
		width: 32px;			
	}

	&__title {
		display: flex;
		flex-direction: column;		
		margin: 0;
	}

	&__name {
		font-weight: 600;
		font-size: 16px;
		margin-bottom: 4px;

		@media (max-width: 768px) {
			font-size: 14px;
		}
	}

	&__network {		
		display: flex;
		align-items: center;
		font-size: 14px;	
		color: #4C76AA;

		@media (max-width: 768px) {
			font-size: 12px;
		}
	}

	&__value {
		font-weight: 500;
		font-size: 16px;
		color: #142438;
		margin-bottom: 4px;

		@media (max-width: 768px) {
			font-size: 14px;
		}
	}

	&__currency {
		font-weight: 500;
		font-size: 14px;
		color: #4C76AA;	
		
		@media (max-width: 768px) {
			font-size: 12px;
		}
	}

	&__token {
		font-size: .94vw;
		color: #4C76AA;
	}
}