.max-button {	
	background: none;
	border: none;
	font-size: 12px;
	font-weight: 500;
	color: var(--primary-blue);
	cursor: pointer;
	user-select: none;		
	width: fit-content;
	padding: 0;
	margin-right: 4px;

	&:disabled {
		color: rgba(149, 153, 173, 0.35);
		cursor: auto;
	}
}