.form-network-list-popup {
	display: grid;
	grid-template-columns: repeat(2, minmax(183px, 1fr));
	gap: 18px 21px;
	background: #F8F8F8;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
	border-radius: 12px;
	position: absolute;
	right: 28px;
	bottom: -18px;
	max-width: 420px;
	transform: translateY(100%);
	transition: all .3s;
	opacity: 0;
	z-index: -1;
	visibility: hidden;
	padding: 16px;

	@media (max-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
		width: 90vw;
		gap: 8px 16px;	
		padding: 8px;	
	}

	&.active {		
		opacity: 1;
		z-index: 10;
		visibility: visible;
	}

	&_from {		
		right: auto;
		left: 0;

		@media (max-width: 768px) {
			left: 50%;
			transform: translateY(100%) translateX(-50%);			
		}

		&.active {
			bottom: -8px;

			@media (max-width: 768px) {				
				bottom: 0;
			}
		}
	}

	&_to {		
		right: 0;	
		
		@media (max-width: 768px) {
			right: auto;
			left: 50%;
			transform: translateY(100%) translateX(-50%);			
		}

		&.active {
			bottom: -8px;

			@media (max-width: 768px) {
				bottom: -8px;
			}
		}
	}
	
	&_choose-token {						
		@media (max-width: 768px) {			
			left: 50%;
			transform: translateY(100%) translateX(-50%);			
		}
		
		&.active {
			bottom: -28px;

			@media (max-width: 768px) {			
				bottom: 0;			
			}
		}
	}

	&_networks {
		background-color: white;
		
		&.active {
			bottom: -8px;
			left: 0;
			width: fit-content;
		}
	}

	&__item {
		display: flex;
		align-items: flex-start;
		cursor: pointer;
		border-radius: 8px;
		transition: all .2s;
		padding: 8px;

		&:hover {
			background-color: #F2F2F2;
		}

		&-image {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			background-color: #F2F2F2;
			border-radius: 6px;
			margin-right: 12px;

			img {
				max-width: 32px;
				width: auto;
			}
		}

		&-name {
			display: flex;
			flex-direction: column;
		}

		&-title {
			font-size: 20px;
			font-weight: 700;
			margin: 0 0 4px;

			@media (max-width: 768px) {
				font-size: 16px;
			}
		}

		&-subtitle {
			font-size: 18px;
			color: #4C76AA;
			text-transform: uppercase;

			@media (max-width: 768px) {
				font-size: 14px;
			}
		}
	}
}