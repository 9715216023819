.tooltip-popup {
	background: rgba(20, 36, 56, 0.95);
	border-radius: 8px;
	font-size: 12px;
	color: white;
	line-height: 1.2;	
	position: absolute;	
	top: 0;
	left: -6px;
	transform: translateY(calc(-100% - 8px));
	visibility: hidden;
	z-index: -1;
	opacity: 0;
	transition: all .2s;
	padding: 8px;	

	&_fee {
		display: flex;
		flex-direction: column;
		gap: 3px;

		span {
			white-space: nowrap;
		}
	}

	&::after {
		content: '';
		display: block;	
		width: 12px;
		height: 6px;		
		background: url('../img/tooltip-pointer.svg') no-repeat center;
		background-size: contain;
		position: absolute;	
		bottom: -6px;
		left: 12px;	
	}	

	&_num {
		display: flex;
		align-items: center;
		top: auto;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, calc(100% + 8px));
		height: 25px;
		padding: 0 8px;

		@media (max-width: 768px) {
			justify-content: center;
			left: 0;
			transform: translate(-59px, calc(100% + 8px));
			width: calc(100vw - 10px);
			height: 28px;
			font-size: 13px;			
			padding: 0 6px;
		}

		&::after {
			top: -6px;
			bottom: auto;
			left: calc(50% - 6px);
			transform: rotate(180deg);
			
			@media (max-width: 768px) {
				left: 90px;
			}
		}
	}

	&_white {
		display: flex;
		flex-wrap: wrap;
		gap: 10px 12px;
		background: white;
		box-shadow: 0px 0px 5.98214px rgba(0, 0, 0, 0.06), 0px 11.9643px 23.9286px rgba(0, 0, 0, 0.1);
		left: -36px;
		transform: translateY(calc(-100% - 16px));
		width: 90vw;
		max-width: 633px;
		padding: 20px;

		@media (max-width: 768px) {
			position: fixed;
			left: 20px;
			top: 130px;
			transform: none;
			width: calc(100vw - 40px);
		}

		&::after {
			width: 24px;
			height: 16px;		
			background: url('../img/tooltip-pointer_white.svg') no-repeat center;		
			bottom: -14px;
			left: 28px;	

			@media (max-width: 768px) {
				display: none;
			}
		}	
	}

	&__item {
		display: flex;
		align-items: center;
		gap: 6px;
		font-weight: 400;
		font-size: 14px;		
		
		span {
			color: black !important;
		}
	}
}

.tooltip-cnt {
	cursor: pointer;
	position: relative;
}

.tooltip-cnt:hover .tooltip-popup {
	visibility: visible;
	opacity: 1;
	z-index: 999;
}