@keyframes bgFade {
	from {
		background: rgba(0, 113, 255, 0.2);
	}
	to {
		background: white;
	}
}

.transfers-faq {
	display: flex;
	align-items: flex-start;
	gap: 40px;

	&__menu-block {
		position: sticky;
		top: 20px;
		width: 326px;
		max-height: calc(100vh - 40px);		
		flex: none;
		background: #FFFFFF;
		box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.02);		
		border-radius: 32px;	
		
		@media (max-width: 768px) {
			display: none;
		}
	}

	&__menu-header {
		border-bottom: 1px solid rgba(187, 197, 211, 0.32);
		padding: 26px 36px 18px;
	}

	&__menu-title {
		margin: 0;
	}

	&__menu {	
		max-height: calc(100vh - 138px);
		overflow-y: scroll;
		padding: 28px 32px 40px;

		&::-webkit-scrollbar {
			width: 11px;
		}

		&::-webkit-scrollbar-thumb {
			width: 4px;
			background-color: var(--primary-blue);			
			border: 4px solid white;
			border-radius: 2px;
		}

		ul {
			display: flex;
			flex-direction: column;
			gap: 20px;
			padding: 0;
			margin: 0;

			li {
				display: flex;				
				gap: 6px;
				list-style: none;
				font-weight: 700;
				font-size: 16px;
				line-height: 1.25;

				&::before {
					content: '';
					width: 24px;
					height: 24px;
					background: url('../img/flag-marker.svg') no-repeat center;
					background-size: contain;
					flex: none;
				}

				a {
					text-decoration: none;
					color: #292D32;
					transition: all .2s;

					&:hover {
						color: var(--primary-blue);
					}
				}
			}
		}
	}

	&__title {
		font-weight: 800;
		font-size: 24px;
		line-height: 1.25;
		color: #142438;
		margin: 0 0 15px;

		@media (min-width: 1920px) {
			font-size: 1.25vw;		
		}
	}

	&__content {
		display: flex;
		flex-direction: column;				
		flex: auto;
		background: #FFFFFF;
		box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.02);
		border-radius: 32px;		
		padding: 35px 55px 50px;		
		
		@media (max-width: 768px) {
			padding: 30px 20px;		
		}
	}

	&__item {
		border-radius: 18px;
		padding: 20px;

		&:target {
			animation-name: bgFade;
			animation-duration: 3s;
			animation-iteration-count: 1;
		}

		&-text {
			font-weight: 500;
			font-size: 14px;
			line-height: 1.57;			
			color: #142438;
			margin: 0;

			@media (min-width: 1920px) {
				font-size: .73vw;		
			}
		}
	}

}