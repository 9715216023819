.checkbox {
	display: flex;
	align-items: center;
	gap: 9px;
	cursor: pointer;
	width: fit-content;

	&__input {
		position: absolute;
		z-index: -1;
		visibility: hidden;
		opacity: 0;
		width: 1px;
		height: 1px;
		outline: none;
	}

	&__btn {
		width: 20px;
		height: 20px;
		border-radius: 4px;
		border: 1px solid rgba(0, 66, 105, 0.28);		
		transition: all .1s;
	}

	&__input:checked + &__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		border-color: var(--primary-blue);
		background-color: #0078D2;

		&::after {
			content: '';
			width: 12px;
			height: 10px;
			background: url('../img/checkbox-checked.svg') no-repeat center;
			background-size: contain;
		}

		&:hover {
			background-color: var(--primary-blue);
		}
	}
}