.token-cnt {	
	display: flex;
	align-items: center;
	gap: 9px;
	flex: auto;
	background: #F7F9FC;
	border-radius: 12px;
	padding: 10px;

	@media (max-width: 768px) {		
		align-items: flex-start;
	}

	&.column {
		@media (max-width: 768px) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__icon {
		position: relative;
		width: 32px;
		height: 32px;

		@media (max-width: 768px) {
			width: 26px;
			height: 26px;
		}

		&-token {
			width: 32px;
			height: 32px;
			border-radius: 50%;

			@media (max-width: 768px) {
				width: 26px;
				height: 26px;
			}
		}

		&-network {
			width: 14px;
			height: 14px;
			border-radius: 50%;
			border: 1px #F7F9FC solid;
			position: absolute;
			bottom: -3px;
			right: -3px;

			@media (max-width: 768px) {
				width: 12px;
				height: 12px;
			}
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		gap: 6px;
	}

	&.column &__info {
		@media (max-width: 768px) {
			align-items: center;
		}
	}

	&__value {
		font-weight: 800;
		font-size: 16px;
		line-height: 1;

		@media (max-width: 768px) {
			font-size: 14px;
		}
	}

	&__caption {
		display: flex;
		align-items: center;
		gap: 6px;
		font-size: 12px;
		line-height: 1;
		color: #9599AD;

		@media (max-width: 768px) {
			flex-direction: column;
			align-items: flex-start;
		}

		span {
			display: flex;
			align-items: center;
			gap: 6px;
			color: #142438;
			
			&:not(:first-child) {
				color: #626E82;

				&::before {
					content: '';
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background: rgba(149, 153, 173, 0.45);

					@media (max-width: 768px) {
						display: none;
					}
				}				
			}
		}
	}

	&.column &__caption {
		@media (max-width: 768px) {
			flex-direction: column-reverse;
			align-items: center;
		}
	}
}