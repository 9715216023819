.hero-section {
  position: relative;
  background-color: #0071FF;  
  max-height: 50.8vw;  
  padding: 30px 0 0;  

  @media (max-width: 768px) {
    max-height: none;
    height: auto;
    padding-bottom: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10.42vw 40px 17.3vw;

    @media (min-width: 1921px) {
      padding-top: 200px;
      padding-bottom: 332px;
    }

    @media (max-width: 768px) {      
      padding: 60px 24px 55px;
    }
  }

  &__title {
    color: white;
    text-align: center;
    margin: 0 0 .83vw;

    @media (min-width: 1921px) {
      margin: 0 0 16px;
    }    

    @media (max-width: 768px) {      
      margin: 0 0 37px;
    }
  }

  &__subtitle {
    color: white;
    opacity: .75;
    font-size: 1.25vw;
    margin: 0 0 3.9vw;

    @media (min-width: 1921px) {
      font-size: 24px;
      margin: 0 0 75px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    margin-bottom: 2.5vw;

    @media (min-width: 1921px) {      
      margin-bottom: 48px;
    }

    @media (max-width: 768px) {
      margin-bottom: 24px;
    }
  }
  
  &__tabs-container {
    display: flex;    
    height: 2.7vw;
    border-radius: 18px;
    background-color: #2660A7;
    margin-right: 16px;

    @media (min-width: 1921px) {      
      height: 52px;
    }

    @media (max-width: 768px) {
      height: 52px;
      margin: 0;
    }
  }

  &__tab {
    display: flex;
    align-items: center;
    height: 2.7vw;
    border-radius: 18px;
    font-size: 1.04vw;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    user-select: none;
    transition: all .2s;
    padding: 0 16px;

    @media (min-width: 1921px) {
      font-size: 20px;
      height: 52px;
    }   

    @media (max-width: 768px) {
      font-size: 18px;
      height: 52px;
      padding: 0 11px;
    }
    
    &:hover {
      background-color: #3387F0;
    }

    &::before {
      content: '';
      width: 1.25vw;
      height: 1.25vw;
      border-radius: 50%;
      background: #12335B no-repeat center;
      background-size: 50%;
      margin-right: 6px;    
      
      @media (min-width: 1921px) {
        width: 24px;
        height: 24px;      
        background-size: auto;  
      } 

      @media (max-width: 768px) {
        width: 24px;
        height: 24px;
      }
    }

    &_classic::before {
      background-image: url('../img/tab-classic-icon.svg');
    }

    &_wrapped::before {
      background-image: url('../img/tab-wrapped-icon.svg');
    }

    &_nft::before {
      background-image: url('../img/tab-nft-icon.svg');
    }

    &.active {
      background-color: #fff;
      color: var(--font-dark);
      cursor: default;

      &::before {        
        background-color: var(--primary-blue);        
      }
    }
  }

  &__tooltip {        
    width: fit-content;
    position: relative;

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.25vw;
      height: 1.25vw;
      font-size: .73vw;
      font-weight: 600;
      color: white;
      background: rgba(196, 196, 196, 0.54);
      text-decoration: none;
      border-radius: 50%;
      cursor: pointer;
      transition: all .2s;
      user-select: none;

      @media (min-width: 1921px) {
        width: 24px;
        height: 24px;    
        font-size: 14px;        
      } 

      @media (max-width: 768px) {
        display: none;
      }

      &:hover {
        background-color: #3387F0;
      }
    }

    &-block {      
      min-width: 413px;           
      bottom: calc(1.25vw + 23px);
      left: 11px;      
      transform: translateX(-50%);
      transition: all .3s;
      opacity: 0;
      z-index: -1;
      visibility: hidden;      

      @media (min-width: 1921px) {
        bottom: 47px;       
      }
      
      @media (max-width: 1024px) {
        display: none;
      }            
    }

    &-button:hover + &-block {
      visibility: visible;
      opacity: 1;
      z-index: 10;
    }

    &-title {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 600;      
      margin: 0 0 8px;

      &::before {
        content: '';
        width: 1.25vw;
        height: 1.25vw;
        border-radius: 50%;
        background: var(--primary-blue) no-repeat center;
        background-size: 50%;
        margin-right: 6px;    
        
        @media (min-width: 1921px) {
          width: 24px;
          height: 24px;      
          background-size: auto;  
        } 

        @media (max-width: 768px) {
          width: 24px;
          height: 24px;
        }
      }

      &_classic::before {
        background-image: url('../img/tab-classic-icon.svg');
      }

      &_wrapped::before {
        background-image: url('../img/tab-wrapped-icon.svg');
      }

      &_nft::before {
        background-image: url('../img/tab-nft-icon.svg');
      }
    }

    &-subtitle {
      font-size: 14px;
      font-weight: 500;
      margin: 0 0 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__scroll-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.04vw;
    font-weight: 700;
    color: white;
    position: absolute;
    bottom: 1.93vw;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: 1921px) {
      font-size: 20px;
      bottom: 37px;
    }

    @media (max-width: 768px) {
      position: static;
      transform: translateX(0);
      font-size: 14px;
      margin: 0 auto;
    }

    span {
      margin-bottom: 12px;

      @media (max-width: 768px) {
        margin-bottom: 8px;
      }
    }

    &-button {
      width: 2.29vw;
      height: 2.29vw;
      border-radius: 50%;
      background: #F8F8F8 url('../img/mouse-icon.svg') no-repeat center;
      background-size: 50%;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      cursor: pointer;

      @media (min-width: 1921px) {
        width: 44px;
        height: 44px;
        background-size: auto;
      }

      @media (max-width: 768px) {
        width: 24px;
        height: 24px;        
        background-image: url('../img/scroll-down-icon_mobile.svg');
        background-size: auto;
      }
    }
  }
}
