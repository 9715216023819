.find-bridge-form {
	display: flex;
	align-items: center;	

	@media (max-width: 768px) {
		flex-direction: column;
		width: 100%;
		max-width: 420px;
		margin: 0 auto;
	}

	&__text-input {		
		position: relative;

		@media (max-width: 768px) {				
			width: 100%;			
		}

		&_from {
			input[type="text"] {
				border-radius: 12px 0 0 12px;
				margin-right: 4px;

				@media (max-width: 768px) {
					margin: 0 0 8px;
				}
			}			
		}

		&_to {
			input[type="text"] {
				border-radius: 0 12px 12px 0;
			}		
		}		

		input[type="text"] {			
			background: #fff no-repeat 25px center;
			height: 4.17vw;	
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);				
			border: none;			
			font-size: 1.04vw;
			color: var(--font-dark);
			width: 16.7vw;
			transition: all .2s;
			padding: 0 1.25vw;

			@media (min-width: 1921px) {
				width: 321px;
				height: 80px;	
				font-size: 20px;
				padding: 0 24px;
			}

			@media (max-width: 768px) {				
				width: 100%;
				height: 50px;
				border-radius: 12px;
				font-size: 18px;
				padding: 0 16px;
			}

			&::placeholder {
				color: #6A8CAC;
				opacity: 1;
			}

			&:focus {
				outline: none;
				border: #142438 2px solid;
				background-image: url('../img/search-icon_grey.svg');
				background-position: 18px center;
				padding: 0 24px 0 49px;

				@media (min-width: 1441px) {
					background-size: auto;
				}
				
				@media (min-width: 769px) {
					background-position: .9375vw center;
					background-size: 7%;
					padding: 0 1.25vw 0 2.55vw;
				}
			}			
		}	
		
		&.errored input[type="text"] {
			border: var(--color-error) 2px solid;
		}
	}

	&__input-label {
		position: absolute;
		top: -22px;
		left: 20px;
		opacity: 0;
		visibility: hidden;		
		font-size: 14px;
		font-weight: 800;
		color: #fff;
		transform: translateY(5px);
		transition: all .2s;

		@media (max-width: 1440px) {				
			font-size: .97vw;
			left: 1.4vw;
			top: -16px;			
		}
		
		@media (max-width: 1024px) {				
			font-size: 12px;
			font-weight: 600;
		}
		
		@media (max-width: 768px) {				
			display: none;
		}
	}	

	&__input-error {
		font-size: .68vw;
		color: var(--color-error);
		position: absolute;
		top: 8px;
		right: 16px;
		visibility: hidden;
		opacity: 0;
		transition: all .2s;
		z-index: 2;

		@media (min-width: 1921px) {
			font-size: 13px;
		}

		@media (max-width: 768px) {				
			font-size: 12px;
		}
	}

	&__text-input input[type="text"]:focus + &__input-label {
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
	}	

	&__text-input.errored &__input-error {		
		opacity: 1;
		visibility: visible;
	}

	&__choose-token-btn {
		display: none;
		align-items: center;
		background: none;
		border: none;
		outline: none;
		font-size: 20px;
		font-weight: 600;
		white-space: nowrap;		
		color: white;
		cursor: pointer;				
		transition: all .2s;
		margin: 0 28px;

		@media (min-width: 1921px) {
			width: 210px;
		}
		
		@media (max-width: 768px) {
			font-size: 18px;
			margin: 24px 0;
		}

		&::before {
			content: '';
			width: 33px;
			height: 32px;
			background: url('../img/coin-icon.svg') no-repeat center;		
			background-size: contain;
			margin-right: 10px;	
		}

		&::after {
			content: '';
			width: 11px;
			height: 8px;
			background: url('../img/dropdown-arrow_white.svg') no-repeat center;
			transition: all .2s;	
			margin-left: 10px;		
		}

		&:hover::after {
			transform: translateY(3px);
		}

		&.active {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&__choose-token-input {
		display: none;		
		background: transparent url('../img/search-icon_white.svg') no-repeat 5px center;
		border-bottom: white 1px solid;
		border-top: none;
		border-left: none;
		border-right: none;
		color: #fff;
		outline: none;
		height: 40px;
		width: 10.9vw;
		font-size: 20px;
		padding: 0 0 0 32px;
		margin: 0 28px;

		@media (min-width: 1921px) {
			width: 210px;
		}

		@media (max-width: 768px) {
			width: 210px;
			margin: 24px 0;
		}

		&.active {
			display: flex;
		}
	}

	&__choose-token-input-cnt {
		position: relative;
	}

	&__submit-btn {
		
		@media (max-width: 768px) {
			width: 210px;	
		}
		
		@media (max-width: 420px) {
			width: 100%;
		}
	}
}