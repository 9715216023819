.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  text-decoration: none;
  border: none;
  background: none;  
  white-space: nowrap;
  transition: all .2s;
  cursor: pointer;
  
  &_blue { 
    background-color: var(--primary-blue);
    font-size: 18px;
    font-weight: 700;
    width: 100%;
  }

  &_primary {
    height: 48px;
    border-radius: 12px;
    background-color: #003980;
    font-size: 20px;
    font-weight: 800;
    color: white;  
    border: none;  
    padding: 0 2.34vw;  
    
    @media (max-width: 768px) {
      height: 56px;
      font-size: 16px;
    }

    &:disabled {
      background-color: #EDF2F4;
      color: #AFBBC2;

      &:hover {
        background-color: #EDF2F4;
        color: #AFBBC2;
        cursor: auto;
      }
    }

    &:hover {
      background-color: #0A3468;
    }    
  }
  
  &_middle {
    height: 48px;    
    font-size: 16px;
    font-weight: 800;
    background-color: #003980;
    border-radius: 12px;
    padding: 0 28px;

    &:hover {
      background-color: #0A3468;
    }
  }  

  &_outlined {
    border: white 1px solid;
    background: none;
    border-radius: 12px;
  }
  
  &_outlined-red {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #142438;
    height: 52px;
    border: #FF0032 1px solid;
    border-radius: 18px;
    background: none;    
    font-size: 15px;
    font-weight: 600;

    @media (max-width: 768px) {
      border-radius: 10px;
    }

    &:hover {
      background: none;
    }
  }

  &_dropdown {        
    &.active img {
      transform: rotate(180deg);
    }

    img {
      transition: transform .2s;
    }
  }

  &_popup {
    height: 40px;
    border-radius: 10px;
    font-weight: 800;
    font-size: 16px;
    line-height: 1;
    transition: .2s all;
    padding: 0 33px;
  }

  &_popup-primary {    
    color: #FFFFFF;
    background: #0071FF;  
    
    &:hover {
      background: #124D96;
    }
  }

  &_popup-secondary {  
    color: #0071FF;
    background: rgba(0, 113, 255, 0.18); 
    
    &:hover {
      background: rgba(0, 113, 255, 0.1);
    }
  }
}
