.transfers-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	width: fit-content;
	height: fit-content;
	margin: 0 auto;

	@media (max-width: 768px) {
		width: 100%;
		height: auto;
		padding: 0;
		margin: 0;
	}

	&__subscription {
		display: block;
		width: fit-content;
		font-size: 14px;
		color: var(--primary-blue);
		background: #EBF5FF;
		border-radius: 6px;
		padding: 4px 7px;
		margin: 0 auto;
	}
}