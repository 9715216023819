.marked-list {	
	padding-left: 20px;
	margin: 10px 0 0;

	li {
		font-weight: 600;
		font-size: 16px;
		line-height: 1.75;		
		color: #52648E;
	}
}